import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class AssetSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.packageName',
      name: 'packageName',
    },
    {
      label: 'label.bundleName',
      name: 'bundleName',
    },
    {
      label: 'label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },

    {
      label: 'label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:priceUnitStatus',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-9';
}

AssetSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AssetSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AssetSearchForm);
