import * as types from './types';

// Customer-start //
export function getReportNewCustomer(payload, cb) {
  return {
    type: types.GET_REPORT_NEW_CUSTOMER,
    payload,
    cb,
  };
}

export function getReportNewCustomerSuccess(payload, cb) {
  return {
    type: types.GET_REPORT_NEW_CUSTOMER_SUCCESS,
    payload,
    cb,
  };
}

export function getAllListNewCustomer(payload, cb) {
  return {
    type: types.GET_ALL_LIST_NEW_CUSTOMER,
    payload,
    cb,
  };
}

export function getAllListNewCustomerSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_NEW_CUSTOMER_SUCCESS,
    payload,
    cb,
  };
}

export function getAccountClosure(payload, cb) {
  return {
    type: types.GET_REPORT_ACCOUNT_CLOSURE,
    payload,
    cb,
  };
}

export function getAccountClosureSuccess(payload, cb) {
  return {
    type: types.GET_REPORT_ACCOUNT_CLOSURE_SUCCESS,
    payload,
    cb,
  };
}

export function getAllAccountClosure(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACCOUNT_CLOSURE,
    payload,
    cb,
  };
}

export function getAllAccountClosureSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACCOUNT_CLOSURE_SUCCESS,
    payload,
    cb,
  };
}
// Customer-end //

// Order
export function getOrderReport(payload, cb) {
  return {
    type: types.GET_LIST_ORDER,
    payload,
    cb,
  };
}

export function getOrderReportSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ORDER_SUCCESS,
    payload,
    cb,
  };
}

export function getAllOrderReport(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ORDER,
    payload,
    cb,
  };
}

export function getAllOrderReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ORDER_SUCCESS,
    payload,
    cb,
  };
}

// Trial Subscription
export function getTrialSubscriptionReport(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getTrialSubscriptionReportSuccess(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

export function getAllTrialSubscriptionReport(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getAllTrialSubscriptionReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

export function getInvoicing(payload, cb) {
  return {
    type: types.GET_LIST_INVOICING,
    payload,
    cb,
  };
}

export function getInvoicingSuccess(payload, cb) {
  return {
    type: types.GET_LIST_INVOICING_SUCCESS,
    payload,
    cb,
  };
}

export function getAllInvoicing(payload, cb) {
  return {
    type: types.GET_ALL_LIST_INVOICING,
    payload,
    cb,
  };
}

export function getAllInvoicingSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_INVOICING_SUCCESS,
    payload,
    cb,
  };
}

export function getListPayment(payload, cb) {
  return {
    type: types.GET_LIST_PAYMENT,
    payload,
    cb,
  };
}

export function getListPaymentSuccess(payload, cb) {
  return {
    type: types.GET_LIST_PAYMENT_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllPayment(payload, cb) {
  return {
    type: types.GET_LIST_ALL_PAYMENT,
    payload,
    cb,
  };
}

export function getListAllPaymentSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ALL_PAYMENT_SUCCESS,
    payload,
    cb,
  };
}

export function getBilling(payload, cb) {
  return {
    type: types.GET_LIST_BILLING,
    payload,
    cb,
  };
}

export function getBillingSuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILLING_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllBilling(payload, cb) {
  return {
    type: types.GET_LIST_ALL_BILLING,
    payload,
    cb,
  };
}

export function getListAllBillingSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ALL_BILLING_SUCCESS,
    payload,
    cb,
  };
}

export function getAgeingSummary(payload, cb) {
  return {
    type: types.GET_LIST_AGEING_SUMMARY,
    payload,
    cb,
  };
}

export function getAgeingSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_AGEING_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllAgeingSummary(payload, cb) {
  return {
    type: types.GET_LIST_ALL_AGEING_SUMMARY,
    payload,
    cb,
  };
}

export function getListAllAgeingSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_ALL_AGEING_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Bill Product Summary
export function getBillProductSummary(payload, cb) {
  return {
    type: types.GET_LIST_BILL_PRODUCT_SUMMARY,
    payload,
    cb,
  };
}

export function getBillProductSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILL_PRODUCT_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBillProductSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILL_PRODUCT_SUMMARY,
    payload,
    cb,
  };
}

export function getAllBillProductSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILL_PRODUCT_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Bill Product Summary
export function getBillTransactionSummary(payload, cb) {
  return {
    type: types.GET_LIST_BILL_TRANSACTION_SUMMARY,
    payload,
    cb,
  };
}

export function getBillTransactionSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBillTransactionSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILL_TRANSACTION_SUMMARY,
    payload,
    cb,
  };
}

export function getAllBillTransactionSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Job Schedule
export function getJobSchedule(payload, cb) {
  return {
    type: types.GET_LIST_JOB_SCHEDULE,
    payload,
    cb,
  };
}

export function getJobScheduleSuccess(payload, cb) {
  return {
    type: types.GET_LIST_JOB_SCHEDULE_SUCCESS,
    payload,
    cb,
  };
}

export function getAllJobSchedule(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_SCHEDULE,
    payload,
    cb,
  };
}

export function getAllJobScheduleSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_SCHEDULE_SUCCESS,
    payload,
    cb,
  };
}

// Job Failed
export function getJobFailed(payload, cb) {
  return {
    type: types.GET_LIST_JOB_FAILED,
    payload,
    cb,
  };
}

export function getJobFailedSuccess(payload, cb) {
  return {
    type: types.GET_LIST_JOB_FAILED_SUCCESS,
    payload,
    cb,
  };
}

export function getAllJobFailed(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_FAILED,
    payload,
    cb,
  };
}

export function getAllJobFailedSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_FAILED_SUCCESS,
    payload,
    cb,
  };
}

// Trial Subscription
export function getTrialSubscription(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getTrialSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

export function getAllTrialSubscription(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getAllTrialSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

// Subscription Expiry
export function getSubscriptionExpiry(payload, cb) {
  return {
    type: types.GET_LIST_SUBSCRIPTION_EXPIRY,
    payload,
    cb,
  };
}

export function getSubscriptionExpirySuccess(payload, cb) {
  return {
    type: types.GET_LIST_SUBSCRIPTION_EXPIRY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllSubscriptionExpiry(payload, cb) {
  return {
    type: types.GET_ALL_LIST_SUBSCRIPTION_EXPIRY,
    payload,
    cb,
  };
}

export function getAllSubscriptionExpirySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_SUBSCRIPTION_EXPIRY_SUCCESS,
    payload,
    cb,
  };
}

// Collection 0-30
export function getCollection030(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_030,
    payload,
    cb,
  };
}

export function getCollection030Success(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_030_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCollection030(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_030,
    payload,
    cb,
  };
}

export function getAllCollection030Success(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_030_SUCCESS,
    payload,
    cb,
  };
}

// Collection 30-60
export function getCollection3060(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_3060,
    payload,
    cb,
  };
}

export function getCollection3060Success(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_3060_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCollection3060(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_3060,
    payload,
    cb,
  };
}

export function getAllCollection3060Success(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_3060_SUCCESS,
    payload,
    cb,
  };
}

// Collection 60-90
export function getCollection6090(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_6090,
    payload,
    cb,
  };
}

export function getCollection6090Success(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_6090_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCollection6090(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_6090,
    payload,
    cb,
  };
}

export function getAllCollection6090Success(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_6090_SUCCESS,
    payload,
    cb,
  };
}

// Collection more than 90
export function getCollectionMoreThan90(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_MORE_THAN_90,
    payload,
    cb,
  };
}

export function getCollectionMoreThan90Success(payload, cb) {
  return {
    type: types.GET_LIST_COLLECTION_MORE_THAN_90_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCollectionMoreThan90(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_MORE_THAN_90,
    payload,
    cb,
  };
}

export function getAllCollectionMoreThan90Success(payload, cb) {
  return {
    type: types.GET_ALL_LIST_COLLECTION_MORE_THAN_90_SUCCESS,
    payload,
    cb,
  };
}

// Failed Payment
export function getFailedPayment(payload, cb) {
  return {
    type: types.GET_LIST_FAILED_PAYMENT,
    payload,
    cb,
  };
}

export function getFailedPaymentSuccess(payload, cb) {
  return {
    type: types.GET_LIST_FAILED_PAYMENT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllFailedPayment(payload, cb) {
  return {
    type: types.GET_ALL_LIST_FAILED_PAYMENT,
    payload,
    cb,
  };
}

export function getAllFailedPaymentSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_FAILED_PAYMENT_SUCCESS,
    payload,
    cb,
  };
}

// Payment Suspense
export function getPaymentSuspense(payload, cb) {
  return {
    type: types.GET_LIST_PAYMENT_SUSPENSE,
    payload,
    cb,
  };
}

export function getPaymentSuspenseSuccess(payload, cb) {
  return {
    type: types.GET_LIST_PAYMENT_SUSPENSE_SUCCESS,
    payload,
    cb,
  };
}

export function getAllPaymentSuspense(payload, cb) {
  return {
    type: types.GET_ALL_LIST_PAYMENT_SUSPENSE,
    payload,
    cb,
  };
}

export function getAllPaymentSuspenseSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_PAYMENT_SUSPENSE_SUCCESS,
    payload,
    cb,
  };
}

// AR Ops details
export function getAROpsDetails(payload, cb) {
  return {
    type: types.GET_LIST_AR_OPS_DETAILS,
    payload,
    cb,
  };
}

export function getAROpsDetailsSuccess(payload, cb) {
  return {
    type: types.GET_LIST_AR_OPS_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getAllAROpsDetails(payload, cb) {
  return {
    type: types.GET_ALL_LIST_AR_OPS_DETAILS,
    payload,
    cb,
  };
}

export function getAllAROpsDetailsSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_AR_OPS_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

// AR Open Dispute
export function getOpenDispute(payload, cb) {
  return {
    type: types.GET_LIST_OPEN_DISPUTE,
    payload,
    cb,
  };
}

export function getOpenDisputeSuccess(payload, cb) {
  return {
    type: types.GET_LIST_OPEN_DISPUTE_SUCCESS,
    payload,
    cb,
  };
}

export function getAllOpenDispute(payload, cb) {
  return {
    type: types.GET_ALL_LIST_OPEN_DISPUTE,
    payload,
    cb,
  };
}

export function getAllOpenDisputeSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_OPEN_DISPUTE_SUCCESS,
    payload,
    cb,
  };
}

// AR Open Write Off
export function getOpenWriteOff(payload, cb) {
  return {
    type: types.GET_LIST_OPEN_WRITE_OFF,
    payload,
    cb,
  };
}

export function getOpenWriteOffSuccess(payload, cb) {
  return {
    type: types.GET_LIST_OPEN_WRITE_OFF_SUCCESS,
    payload,
    cb,
  };
}

export function getAllOpenWriteOff(payload, cb) {
  return {
    type: types.GET_ALL_LIST_OPEN_WRITE_OFF,
    payload,
    cb,
  };
}

export function getAllOpenWriteOffSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_OPEN_WRITE_OFF_SUCCESS,
    payload,
    cb,
  };
}

// Unbilled Transactions
export function getUnbilledTransactions(payload, cb) {
  return {
    type: types.GET_LIST_BILLED_TRANSACTIONS,
    payload,
    cb,
  };
}

export function getUnbilledTransactionsSuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILLED_TRANSACTIONS_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUnbilledTransactions(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLED_TRANSACTIONS,
    payload,
    cb,
  };
}

export function getAllUnbilledTransactionsSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLED_TRANSACTIONS_SUCCESS,
    payload,
    cb,
  };
}

// Billing Invoicing
export function getListBillingInvoicing(payload, cb) {
  return {
    type: types.GET_LIST_BILLING_INVOICING,
    payload,
    cb,
  };
}

export function getListBillingInvoicingSuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILLING_INVOICING_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllBillingInvoicing(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLING_INVOICING,
    payload,
    cb,
  };
}

export function getListAllBillingInvoicingSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLING_INVOICING_SUCCESS,
    payload,
    cb,
  };
}

// Billing AR
export function getBillingAR(payload, cb) {
  return {
    type: types.GET_LIST_BILLING_AR,
    payload,
    cb,
  };
}

export function getBillingARSuccess(payload, cb) {
  return {
    type: types.GET_LIST_BILLING_AR_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBillingAR(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLING_AR,
    payload,
    cb,
  };
}

export function getAllBillingARSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_BILLING_AR_SUCCESS,
    payload,
    cb,
  };
}

// Usage -Summary
export function getUsageSummary(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_SUMMARY,
    payload,
    cb,
  };
}

export function getUsageSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_SUMMARY,
    payload,
    cb,
  };
}

export function getAllUsageSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Usage - Detail
export function getUsageDetail(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_DETAIL,
    payload,
    cb,
  };
}

export function getUsageDetailSuccess(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageDetail(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_DETAIL,
    payload,
    cb,
  };
}

export function getAllUsageDetailSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

// Usage - Reconciliation Summary
export function getUsageReconciliation(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_RECONCILIATION_SUMMARY,
    payload,
    cb,
  };
}

export function getUsageReconciliationSuccess(payload, cb) {
  return {
    type: types.GET_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageReconciliation(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY,
    payload,
    cb,
  };
}

export function getAllUsageReconciliationSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Revenue - Revenue Summary
export function getRevenueSummary(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_SUMMARY,
    payload,
    cb,
  };
}

export function getRevenueSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRevenueSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_SUMMARY,
    payload,
    cb,
  };
}

export function getAllRevenueSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Revenue - Revenue Recognition
export function getRevenueRecognition(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_RECOGNITION,
    payload,
    cb,
  };
}

export function getRevenueRecognitionSuccess(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_RECOGNITION_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRevenueRecognition(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_RECOGNITION,
    payload,
    cb,
  };
}

export function getAllRevenueRecognitionSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_RECOGNITION_SUCCESS,
    payload,
    cb,
  };
}

// Get-Active-Subscription
export function getActiveSubscription(payload, cb) {
  return {
    type: types.GET_LIST_ACTIVE_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getActiveSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ACTIVE_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

export function getAllActiveSubscription(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACTIVE_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getAllActiveSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACTIVE_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

// Get Job Details
export function getJobDetail(payload, cb) {
  return {
    type: types.GET_LIST_JOB_DETAIL,
    payload,
    cb,
  };
}

export function getJobDetailSuccess(payload, cb) {
  return {
    type: types.GET_LIST_JOB_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

export function getAllJobDetail(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_DETAIL,
    payload,
    cb,
  };
}

export function getAllJobDetailSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_JOB_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

// Get Tax Summary
export function getTaxSummary(payload, cb) {
  return {
    type: types.GET_LIST_TAX_SUMMARY,
    payload,
    cb,
  };
}

export function getTaxSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_TAX_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllTaxSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TAX_SUMMARY,
    payload,
    cb,
  };
}

export function getListAllTaxSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TAX_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Get Tax Detail
export function getTaxDetail(payload, cb) {
  return {
    type: types.GET_LIST_TAX_DETAIL,
    payload,
    cb,
  };
}

export function getTaxDetailSuccess(payload, cb) {
  return {
    type: types.GET_LIST_TAX_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllTaxDetail(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TAX_DETAIL,
    payload,
    cb,
  };
}

export function getListAllTaxDetailSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TAX_DETAIL_SUCCESS,
    payload,
    cb,
  };
}

// Get Subscription Contract Expiry
export function getSubscriptionContractExpiry(payload, cb) {
  return {
    type: types.GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY,
    payload,
    cb,
  };
}

export function getSubscriptionContractExpirySuccess(payload, cb) {
  return {
    type: types.GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllSubscriptionContractExpiry(payload, cb) {
  return {
    type: types.GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY,
    payload,
    cb,
  };
}

export function getListAllSubscriptionContractExpirySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS,
    payload,
    cb,
  };
}

// Get Trial To Paid Subscription
export function getTrialToPaidSubscription(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getTrialToPaidSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllTrialToPaidSubscription(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION,
    payload,
    cb,
  };
}

export function getListAllTrialToPaidSubscriptionSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS,
    payload,
    cb,
  };
}

// Get Revenue Forecast
export function getRevenueForecast(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_FORCEAST,
    payload,
    cb,
  };
}

export function getRevenueForecastSuccess(payload, cb) {
  return {
    type: types.GET_LIST_REVENUE_FORCEAST_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRevenueForecast(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_FORCEAST,
    payload,
    cb,
  };
}

export function getListAllRevenueForecastSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_REVENUE_FORCEAST_SUCCESS,
    payload,
    cb,
  };
}

// Get Revenue MMR
export function getMRRForecast(payload, cb) {
  return {
    type: types.GET_LIST_MMR_FORCEAST,
    payload,
    cb,
  };
}

export function getMRRForecastSuccess(payload, cb) {
  return {
    type: types.GET_LIST_MMR_FORCEAST_SUCCESS,
    payload,
    cb,
  };
}

export function getAllMRRForecast(payload, cb) {
  return {
    type: types.GET_ALL_LIST_MMR_FORCEAST,
    payload,
    cb,
  };
}

export function getListAllMRRForecastSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_MMR_FORCEAST_SUCCESS,
    payload,
    cb,
  };
}

// Volume Amount By Usage Type
export function getVolumeAmountByUsageType(payload, cb) {
  return {
    type: types.GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE,
    payload,
    cb,
  };
}

export function getVolumeAmountByUsageTypeSuccess(payload, cb) {
  return {
    type: types.GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllVolumeAmountByUsageType(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE,
    payload,
    cb,
  };
}

export function getListAllVolumeAmountByUsageTypeSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS,
    payload,
    cb,
  };
}

// Volume Amount Operator
export function getVolumeAmountOperator(payload, cb) {
  return {
    type: types.GET_LIST_VOLUME_AMOUNT_OPERATOR,
    payload,
    cb,
  };
}

export function getVolumeAmountOperatorSuccess(payload, cb) {
  return {
    type: types.GET_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllVolumeAmountOperator(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR,
    payload,
    cb,
  };
}

export function getListAllVolumeAmountOperatorSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS,
    payload,
    cb,
  };
}

// Volume Amount Operator
export function getExcessVoiceTraffic(payload, cb) {
  return {
    type: types.GET_LIST_EXCESS_VOICE_TRAFFIC,
    payload,
    cb,
  };
}

export function getExcessVoiceTrafficSuccess(payload, cb) {
  return {
    type: types.GET_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllExcessVoiceTraffic(payload, cb) {
  return {
    type: types.GET_ALL_LIST_EXCESS_VOICE_TRAFFIC,
    payload,
    cb,
  };
}

export function getListAllExcessVoiceTrafficSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS,
    payload,
    cb,
  };
}

// Voice Traffic By Ld Distination
export function getVoiceTrafficByLdDistination(payload, cb) {
  return {
    type: types.GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION,
    payload,
    cb,
  };
}

export function getVoiceTrafficByLdDistinationSuccess(payload, cb) {
  return {
    type: types.GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllVoiceTrafficByLdDistination(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION,
    payload,
    cb,
  };
}

export function getListAllVoiceTrafficByLdDistinationSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS,
    payload,
    cb,
  };
}

// On Demand
export function getDataServiceReportData(payload, cb) {
  return {
    type: types.GET_LIST_ON_DEMAND,
    payload,
    cb,
  };
}

export function getDataServiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ON_DEMAND_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllDataServiceReportData(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ON_DEMAND,
    payload,
    cb,
  };
}

export function getListAllDataServiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ON_DEMAND_SUCCESS,
    payload,
    cb,
  };
}

// Raw Interconnect Sms Report Data
export function getRawInterconnectSmsReportData(payload, cb) {
  return {
    type: types.GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA,
    payload,
    cb,
  };
}

export function getRawInterconnectSmsReportDataSuccess(payload, cb) {
  return {
    type: types.GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllRawInterconnectSmsReportData(payload, cb) {
  return {
    type: types.GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA,
    payload,
    cb,
  };
}

export function getListAllRawInterconnectSmsReportDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS,
    payload,
    cb,
  };
}

// Raw Interconnect Voice Report Data
export function getRawInterconnectVoiceReportData(payload, cb) {
  return {
    type: types.GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA,
    payload,
    cb,
  };
}

export function getRawInterconnectVoiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllRawInterconnectVoiceReportData(payload, cb) {
  return {
    type: types.GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA,
    payload,
    cb,
  };
}

export function getListAllRawInterconnectVoiceReportDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS,
    payload,
    cb,
  };
}

// New SMS
export function getNewSmsReportData(payload, cb) {
  return {
    type: types.GET_LIST_NEW_SMS,
    payload,
    cb,
  };
}

export function getNewSmsReportDataSuccess(payload, cb) {
  return {
    type: types.GET_LIST_NEW_SMS_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllNewSmsReportData(payload, cb) {
  return {
    type: types.GET_ALL_LIST_NEW_SMS,
    payload,
    cb,
  };
}

export function getListAllNewSmsReportDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_NEW_SMS_SUCCESS,
    payload,
    cb,
  };
}

// Credit Notes
export function getCreditNote(payload, cb) {
  return {
    type: types.GET_LIST_CREDIT_NOTES,
    payload,
    cb,
  };
}

export function getCreditNoteSuccess(payload, cb) {
  return {
    type: types.GET_LIST_CREDIT_NOTES_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllCreditNote(payload, cb) {
  return {
    type: types.GET_ALL_LIST_CREDIT_NOTES,
    payload,
    cb,
  };
}

export function getListAllCreditNoteSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_CREDIT_NOTES_SUCCESS,
    payload,
    cb,
  };
}

// Voice Call Type Summary Report
export function getVoiceCallTypeSummary(payload, cb) {
  return {
    type: types.GET_LIST_VOICE_CALL_TYPE_SUMMARY,
    payload,
    cb,
  };
}

export function getVoiceCallTypeSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllVoiceCallTypeSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY,
    payload,
    cb,
  };
}

export function getListAllVoiceCallTypeSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Internet Operator Data Cost
export function getInternetOperatorDataCost(payload, cb) {
  return {
    type: types.GET_LIST_INTERNET_OPERATOR_DATA_COST,
    payload,
    cb,
  };
}

export function getInternetOperatorDataCostSuccess(payload, cb) {
  return {
    type: types.GET_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllInternetOperatorDataCost(payload, cb) {
  return {
    type: types.GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST,
    payload,
    cb,
  };
}

export function getListAllInternetOperatorDataCostSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS,
    payload,
    cb,
  };
}

// Get Telecom Summary
export function getTelecomSummary(payload, cb) {
  return {
    type: types.GET_LIST_TELECOM_SUMMARY,
    payload,
    cb,
  };
}

export function getTelecomSummarySuccess(payload, cb) {
  return {
    type: types.GET_LIST_TELECOM_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

export function getListAllTelecomSummary(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TELECOM_SUMMARY,
    payload,
    cb,
  };
}

export function getListAllTelecomSummarySuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_TELECOM_SUMMARY_SUCCESS,
    payload,
    cb,
  };
}

// Get Account report
export function getReportAccountReport(payload, cb) {
  return {
    type: types.GET_LIST_ACCOUNT,
    payload,
    cb,
  };
}

export function getReportAccountReportSuccess(payload, cb) {
  return {
    type: types.GET_LIST_ACCOUNT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllListAccountReport(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACCOUNT,
    payload,
    cb,
  };
}

export function getAllListAccountReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_ACCOUNT_SUCCESS,
    payload,
    cb,
  };
}

// Get Raw File
export function getRawFile(payload, cb) {
  return {
    type: types.GET_RAW_FILE,
    payload,
    cb,
  };
}

export function getRawFileSuccess(payload, cb) {
  return {
    type: types.GET_RAW_FILE_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRawFile(payload, cb) {
  return {
    type: types.GET_ALL_RAW_FILE,
    payload,
    cb,
  };
}

export function getAllRawFileSuccess(payload, cb) {
  return {
    type: types.GET_ALL_RAW_FILE_SUCCESS,
    payload,
    cb,
  };
}

export function getUsageTransactionDetailReport(payload, cb) {
  return {
    type: types.GET_REPORT_USAGE_TRANSACTION_DETAILS,
    payload,
    cb,
  };
}

export function getUsageTransactionDetailReportSuccess(payload, cb) {
  return {
    type: types.GET_REPORT_USAGE_TRANSACTION_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageTransactionDetailReport(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_TRANSACTION_DETAILS,
    payload,
    cb,
  };
}

export function getAllUsageTransactionDetailReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_LIST_USAGE_TRANSACTION_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getBillableServicesReport(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICES_REPORT,
    payload,
    cb,
  };
}

export function getBillableServicesReportSuccess(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICES_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBillableServicesReport(payload, cb) {
  return {
    type: types.GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllBillableServicesReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getAccountingReconciliation(payload, cb) {
  return {
    type: types.GET_ACCOUNTING_RECONCILIATION_REPORT,
    payload,
    cb,
  };
}

export function getAccountingReconciliationSuccess(payload, cb) {
  return {
    type: types.GET_ACCOUNTING_RECONCILIATION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllAccountingReconciliation(payload, cb) {
  return {
    type: types.GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllAccountingReconciliationSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getRawCdrData(payload, cb) {
  return {
    type: types.GET_RAW_CDR_DATA_REPORT,
    payload,
    cb,
  };
}

export function getRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_RAW_CDR_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRawCdrData(payload, cb) {
  return {
    type: types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getRawSmsData(payload, cb) {
  return {
    type: types.GET_RAW_SMS_DATA_REPORT,
    payload,
    cb,
  };
}

export function getRawSmsDataSuccess(payload, cb) {
  return {
    type: types.GET_RAW_SMS_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRawSmsData(payload, cb) {
  return {
    type: types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllRawSmsDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS,
    payload,
    cb,
  };
}

export function getNonBroadSoftRawCdrData(payload, cb) {
  return {
    type: types.GET_NON_BROAD_SOFT_RAW_CDR_DATA,
    payload,
    cb,
  };
}

export function getNonBroadSoftRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getAllNonBroadSoftRawCdrData(payload, cb) {
  return {
    type: types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA,
    payload,
    cb,
  };
}

export function getAllNonBroadSoftRawCdrDataSuccess(payload, cb) {
  return {
    type: types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getServicesForcecast(payload, cb) {
  return {
    type: types.GET_SERVICES_FORCECAST,
    payload,
    cb,
  };
}

export function getServicesForcecastSuccess(payload, cb) {
  return {
    type: types.GET_SERVICES_FORCECAST_SUCCESS,
    payload,
    cb,
  };
}

export function getAllServicesForcecast(payload, cb) {
  return {
    type: types.GET_ALL_SERVICES_FORCECAST,
    payload,
    cb,
  };
}

export function getAllServicesForcecastSuccess(payload, cb) {
  return {
    type: types.GET_ALL_SERVICES_FORCECAST_SUCCESS,
    payload,
    cb,
  };
}

export function employeeCostingPNLReport(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_PNL,
    payload,
    cb,
  };
}

export function employeeCostingPNLReportSuccess(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_PNL_SUCCESS,
    payload,
    cb,
  };
}

export function employeeAllCostingPNLReport(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_PNL,
    payload,
    cb,
  };
}

export function employeeAllCostingPNLReportSuccess(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_PNL_SUCCESS,
    payload,
    cb,
  };
}

export function getBillableServicesForecastReport(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICES_FORECAST_REPORT,
    payload,
    cb,
  };
}

export function getBillableServicesForecastReportSuccess(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBillableServicesForecastReport(payload, cb) {
  return {
    type: types.GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT,
    payload,
    cb,
  };
}

export function getAllBillableServicesForecastReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS,
    payload,
    cb,
  };
}
// Object Files Summary
export function getObjectFilesReport(payload, cb) {
  return {
    type: types.GET_OBJECT_FILE_REPORT,
    payload,
    cb,
  };
}

export function getObjectFilesReportSuccess(payload, cb) {
  return {
    type: types.GET_OBJECT_FILE_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllObjectFilesReport(payload, cb) {
  return {
    type: types.GET_ALL_OBJECT_FILE_REPORT,
    payload,
    cb,
  };
}

export function getAllObjectFilesReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_OBJECT_FILE_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// Provisioning Data
export function getProvisioningDataReport(payload, cb) {
  return {
    type: types.GET_PROVISIONING_DATA_REPORT,
    payload,
    cb,
  };
}

export function getProvisioningDataReportSuccess(payload, cb) {
  return {
    type: types.GET_PROVISIONING_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllProvisioningDataReport(payload, cb) {
  return {
    type: types.GET_ALL_PROVISIONING_DATA_REPORT,
    payload,
    cb,
  };
}

export function getAllProvisioningDataReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_PROVISIONING_DATA_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// Provisioning Data
export function billingTrackerReport(payload, cb) {
  return {
    type: types.BILLING_TRACKER_REPORT,
    payload,
    cb,
  };
}

export function billingTrackerReportSuccess(payload, cb) {
  return {
    type: types.BILLING_TRACKER_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function billingAllTrackerReport(payload, cb) {
  return {
    type: types.GET_ALL_BILLING_TRACKER_REPORT,
    payload,
    cb,
  };
}

export function billingAllTrackerReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BILLING_TRACKER_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// employeeCostingYearlyPNLReport
export function employeeCostingYearlyPNLReport(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_YEARLY_PL,
    payload,
    cb,
  };
}

export function employeeCostingYearlyPNLReportSuccess(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_YEARLY_PL_SUCCESS,
    payload,
    cb,
  };
}

export function employeeAllCostingYearlyPNLReport(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_YEARLY_PL,
    payload,
    cb,
  };
}

export function employeeAllCostingYearlyPNLReportSuccess(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_YEARLY_PL_SUCCESS,
    payload,
    cb,
  };
}

// employeeCostingYearlyPNLReport
export function getCommitmentReport(payload, cb) {
  return {
    type: types.GET_COMMITMENT_REPORT,
    payload,
    cb,
  };
}

export function getCommitmentReportSuccess(payload, cb) {
  return {
    type: types.GET_COMMITMENT_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCommitmentReport(payload, cb) {
  return {
    type: types.GET_ALL_COMMITMENT_REPORT,
    payload,
    cb,
  };
}

export function getAllCommitmentReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_COMMITMENT_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getPaymentAgeingReport
export function getPaymentAgeingReport(payload, cb) {
  return {
    type: types.GET_PAYMENT_AGEING_REPORT,
    payload,
    cb,
  };
}

export function getPaymentAgeingReportSuccess(payload, cb) {
  return {
    type: types.GET_PAYMENT_AGEING_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllPaymentAgeingReport(payload, cb) {
  return {
    type: types.GET_ALL_PAYMENT_AGEING_REPORT,
    payload,
    cb,
  };
}

export function getAllPaymentAgeingReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_PAYMENT_AGEING_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getFileProcessingStats
export function getFileProcessingStats(payload, cb) {
  return {
    type: types.GET_FILE_PROCESSING_STATS,
    payload,
    cb,
  };
}

export function getFileProcessingStatsSuccess(payload, cb) {
  return {
    type: types.GET_FILE_PROCESSING_STATS_SUCCESS,
    payload,
    cb,
  };
}

export function getAllFileProcessingStats(payload, cb) {
  return {
    type: types.GET_ALL_FILE_PROCESSING_STATS,
    payload,
    cb,
  };
}

export function getAllFileProcessingStatsSuccess(payload, cb) {
  return {
    type: types.GET_ALL_FILE_PROCESSING_STATS_SUCCESS,
    payload,
    cb,
  };
}

// searchServiceCancellationExtract
export function searchServiceCancellationExtract(payload, cb) {
  return {
    type: types.SEARCH_SERVICE_CANCELLATION_EXTRACT,
    payload,
    cb,
  };
}

export function searchServiceCancellationExtractSuccess(payload, cb) {
  return {
    type: types.SEARCH_SERVICE_CANCELLATION_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllServiceCancellationExtractStats(payload, cb) {
  return {
    type: types.SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT,
    payload,
    cb,
  };
}

export function searchAllServiceCancellationExtractStatsSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

// searchFolioDataExtract
export function searchFolioDataExtract(payload, cb) {
  return {
    type: types.SEARCH_FOLIO_DATA_EXTRACT,
    payload,
    cb,
  };
}

export function searchFolioDataExtractSuccess(payload, cb) {
  return {
    type: types.SEARCH_FOLIO_DATA_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllFolioDataExtract(payload, cb) {
  return {
    type: types.SEARCH_ALL_FOLIO_DATA_EXTRACT,
    payload,
    cb,
  };
}

export function searchAllFolioDataExtractSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_FOLIO_DATA_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

// getBroadsoftZeroDurationCdrsReport
export function getBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getAllBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getNonBroadsoftZeroDurationCdrsReport
export function getNonBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getNonBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllNonBroadsoftZeroDurationCdrsReport(payload, cb) {
  return {
    type: types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    payload,
    cb,
  };
}

export function getAllNonBroadsoftZeroDurationCdrsReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function onResetDefaultData(payload, cb) {
  return {
    type: types.ON_RESET_DEFAUL_DATA,
    payload,
    cb,
  };
}

// searchDetailRecords
export function searchDetailRecords(payload, cb) {
  return {
    type: types.SEARCH_DETAIL_RECORDS_REPORT,
    payload,
    cb,
  };
}

export function searchDetailRecordsSuccess(payload, cb) {
  return {
    type: types.SEARCH_DETAIL_RECORDS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllDetailRecords(payload, cb) {
  return {
    type: types.SEARCH_ALL_DETAIL_RECORDS_REPORT,
    payload,
    cb,
  };
}

export function searchAllDetailRecordsSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_DETAIL_RECORDS_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getBIExtract
export function getBIExtract(payload, cb) {
  return {
    type: types.GET_BI_EXTRACT,
    payload,
    cb,
  };
}

export function getBIExtractSuccess(payload, cb) {
  return {
    type: types.GET_BI_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllBIExtract(payload, cb) {
  return {
    type: types.GET_ALL_BI_EXTRACT,
    payload,
    cb,
  };
}

export function getAllBIExtractSuccess(payload, cb) {
  return {
    type: types.GET_ALL_BI_EXTRACT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00105Report
export function getRegR00105Report(payload, cb) {
  return {
    type: types.GET_REG_R00105_REPORT,
    payload,
    cb,
  };
}

export function getRegR00105ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00105_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00105Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00105_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00105ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00105_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00106Report
export function getRegR00106Report(payload, cb) {
  return {
    type: types.GET_REG_R00106_REPORT,
    payload,
    cb,
  };
}

export function getRegR00106ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00106_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00106Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00106_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00106ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00106_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00501Report
export function getRegR00501Report(payload, cb) {
  return {
    type: types.GET_REG_R00501_REPORT,
    payload,
    cb,
  };
}

export function getRegR00501ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00501_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00501Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00501_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00501ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00501_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00502Report
export function getRegR00502Report(payload, cb) {
  return {
    type: types.GET_REG_R00502_REPORT,
    payload,
    cb,
  };
}

export function getRegR00502ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00502_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00502Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00502_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00502ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00502_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00503Report
export function getRegR00503Report(payload, cb) {
  return {
    type: types.GET_REG_R00503_REPORT,
    payload,
    cb,
  };
}

export function getRegR00503ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00503_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00503Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00503_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00503ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00503_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00504Report
export function getRegR00504Report(payload, cb) {
  return {
    type: types.GET_REG_R00504_REPORT,
    payload,
    cb,
  };
}

export function getRegR00504ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00504_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00504Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00504_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00504ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00504_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00505Report
export function getRegR00505Report(payload, cb) {
  return {
    type: types.GET_REG_R00505_REPORT,
    payload,
    cb,
  };
}

export function getRegR00505ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00505_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00505Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00505_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00505ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00505_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00506Report
export function getRegR00506Report(payload, cb) {
  return {
    type: types.GET_REG_R00506_REPORT,
    payload,
    cb,
  };
}

export function getRegR00506ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00506_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00506Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00506_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00506ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00506_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00401Report
export function getRegR00401Report(payload, cb) {
  return {
    type: types.GET_REG_R00401_REPORT,
    payload,
    cb,
  };
}

export function getRegR00401ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00401_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00401Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00401_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00401ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00401_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getRegR00405Report
export function getRegR00405Report(payload, cb) {
  return {
    type: types.GET_REG_R00405_REPORT,
    payload,
    cb,
  };
}

export function getRegR00405ReportSuccess(payload, cb) {
  return {
    type: types.GET_REG_R00405_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllRegR00405Report(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00405_REPORT,
    payload,
    cb,
  };
}

export function getAllRegR00405ReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_REG_R00405_REPORT_SUCCESS,
    payload,
    cb,
  };
}

// getCreditBureauReport
export function getCreditBureauReport(payload, cb) {
  return {
    type: types.GET_CREDIT_BUREAUR_EPORT,
    payload,
    cb,
  };
}

export function getCreditBureauReportSuccess(payload, cb) {
  return {
    type: types.GET_CREDIT_BUREAUR_EPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllCreditBureauReport(payload, cb) {
  return {
    type: types.GET_ALL_CREDIT_BUREAUR_EPORT,
    payload,
    cb,
  };
}

export function getAllCreditBureauReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_CREDIT_BUREAUR_EPORT_SUCCESS,
    payload,
    cb,
  };
}

// getDataServiceReportDetails
export function getDataServiceReportDetails(payload, cb) {
  return {
    type: types.GET_DATA_SERVICE_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function getAllDataServiceReportDetails(payload, cb) {
  return {
    type: types.GET_ALL_DATA_SERVICE_REPORT_DETAILS,
    payload,
    cb,
  };
}

export function pushKPIExtract(payload, cb) {
  return {
    type: types.PUSH_KPI_EXTRACT,
    payload,
    cb,
  };
}

export function getTaxExemptionReport(payload, cb) {
  return {
    type: types.GET_TAX_EXEMPTION_REPORT,
    payload,
    cb,
  };
}

export function getTaxExemptionReportSuccess(payload, cb) {
  return {
    type: types.GET_TAX_EXEMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllListTaxExemption(payload, cb) {
  return {
    type: types.GET_ALL_TAX_EXEMPTION_REPORT,
    payload,
    cb,
  };
}

export function getAllListTaxExemptionSuccess(payload, cb) {
  return {
    type: types.GET_ALL_TAX_EXEMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getFonatelReport(payload, cb) {
  return {
    type: types.GET_FONATEL_REPORT,
    payload,
    cb,
  };
}

export function getFonatelReportSuccess(payload, cb) {
  return {
    type: types.GET_FONATEL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllFonatelReport(payload, cb) {
  return {
    type: types.GET_ALL_FONATEL_REPORT,
    payload,
    cb,
  };
}

export function getAllFonatelReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_FONATEL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getPhcAssetReport(payload, cb) {
  return {
    type: types.GET_PHC_ASSET_REPORT,
    payload,
    cb,
  };
}

export function getPhcAssetReportSuccess(payload, cb) {
  return {
    type: types.GET_PHC_ASSET_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllListPhcAssetReport(payload, cb) {
  return {
    type: types.GET_ALL_PHC_ASSET_REPORT,
    payload,
    cb,
  };
}

export function getAllListPhcAssetReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_PHC_ASSET_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getServiceReconReport(payload, cb) {
  return {
    type: types.SERVICE_RECON_REPORT,
    payload,
    cb,
  };
}

export function getServiceReconReportSuccess(payload, cb) {
  return {
    type: types.SERVICE_RECON_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllListServiceReconReport(payload, cb) {
  return {
    type: types.GET_ALL_SERVICE_RECON_REPORT,
    payload,
    cb,
  };
}

export function getAllListServiceReconReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_SERVICE_RECON_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function employeeCostingQuarterlyPNLReport(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT,
    payload,
    cb,
  };
}

export function employeeCostingQuarterlyPNLReportSuccess(payload, cb) {
  return {
    type: types.EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function employeeAllCostingQuarterlyPNLReport(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT,
    payload,
    cb,
  };
}

export function employeeAllCostingQuarterlyPNLReportSuccess(payload, cb) {
  return {
    type: types.ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getUsageConsumptionReport(payload, cb) {
  return {
    type: types.GET_USAGE_CONSUMPTION_REPORT,
    payload,
    cb,
  };
}

export function getUsageConsumptionReportSuccess(payload, cb) {
  return {
    type: types.GET_USAGE_CONSUMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageConsumptionReport(payload, cb) {
  return {
    type: types.GET_ALL_USAGE_CONSUMPTION_REPORT,
    payload,
    cb,
  };
}

export function getAllUsageConsumptionReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_USAGE_CONSUMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getUsageAverageConsumptionReport(payload, cb) {
  return {
    type: types.GET_USAGE_AVERAGE_CONSUMPTION_REPORT,
    payload,
    cb,
  };
}

export function getUsageAverageConsumptionReportSuccess(payload, cb) {
  return {
    type: types.GET_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllUsageAverageConsumptionReport(payload, cb) {
  return {
    type: types.GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT,
    payload,
    cb,
  };
}

export function getAllUsageAverageConsumptionReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getEnergyTopupReport(payload, cb) {
  return {
    type: types.GET_ENERGY_TOPUP_REPORT,
    payload,
    cb,
  };
}

export function getEnergyTopupReportSuccess(payload, cb) {
  return {
    type: types.GET_ENERGY_TOPUP_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllEnergyTopupReport(payload, cb) {
  return {
    type: types.GET_ALL_ENERGY_TOPUP_REPORT,
    payload,
    cb,
  };
}

export function getAllEnergyTopupReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ENERGY_TOPUP_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function searchSubscriptionTopUp(payload, cb) {
  return {
    type: types.SEARCH_SUBSCRIPTION_TOP_UP,
    payload,
    cb,
  };
}

export function searchSubscriptionTopUpSuccess(payload, cb) {
  return {
    type: types.SEARCH_SUBSCRIPTION_TOP_UP_SUCCESS,
    payload,
    cb,
  };
}

export function searchAllSubscriptionTopUp(payload, cb) {
  return {
    type: types.SEARCH_ALL_SUBSCRIPTION_TOP_UP,
    payload,
    cb,
  };
}

export function searchAllSubscriptionTopUpSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_SUBSCRIPTION_TOP_UP_SUCCESS,
    payload,
    cb,
  };
}

export function getOrderDetailReport(payload, cb) {
  return {
    type: types.GET_ORDER_DETAIL_REPORT,
    payload,
    cb,
  };
}

export function getOrderDetailReportSuccess(payload, cb) {
  return {
    type: types.GET_ORDER_DETAIL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllOrderDetailReport(payload, cb) {
  return {
    type: types.GET_ALL_ORDER_DETAIL_REPORT,
    payload,
    cb,
  };
}

export function getAllOrderDetailReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ORDER_DETAIL_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getServiceInvoiceSummaryReport(payload, cb) {
  return {
    type: types.GET_SERVICE_INVOICE_SUMMARY_REPORT,
    payload,
    cb,
  };
}

export function getServiceInvoiceSummaryReportSuccess(payload, cb) {
  return {
    type: types.GET_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllServiceInvoiceSummaryReport(payload, cb) {
  return {
    type: types.GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT,
    payload,
    cb,
  };
}

export function getAllServiceInvoiceSummaryReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getItemSummaryReport(payload, cb) {
  return {
    type: types.GET_ITEM_SUMMARY_REPORT,
    payload,
    cb,
  };
}

export function getItemSummaryReportSuccess(payload, cb) {
  return {
    type: types.GET_ITEM_SUMMARY_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllItemSummaryReport(payload, cb) {
  return {
    type: types.GET_ALL_ITEM_SUMMARY_REPORT,
    payload,
    cb,
  };
}

export function getAllItemSummaryReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ITEM_SUMMARY_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAssetReport(payload, cb) {
  return {
    type: types.GET_ASSET_REPORT,
    payload,
    cb,
  };
}

export function getAssetReportSuccess(payload, cb) {
  return {
    type: types.GET_ASSET_REPORT_SUCCESS,
    payload,
    cb,
  };
}

export function getAllAssetReport(payload, cb) {
  return {
    type: types.GET_ALL_ASSET_REPORT,
    payload,
    cb,
  };
}

export function getAllAssetReportSuccess(payload, cb) {
  return {
    type: types.GET_ALL_ASSET_REPORT_SUCCESS,
    payload,
    cb,
  };
}
