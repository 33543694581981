import { fromJS } from 'immutable';
import * as types from './types';

export const initialState = fromJS({
  listNewCustomer: [],
  listAllNewCustomer: [],
  listInvoicing: [],
  listAllInvoicing: [],
  listPayments: [],
  listAllPayments: [],
  listBilling: [],
  listAllBilling: [],
  listAgeingSummary: [],
  listAllAgeingSummary: [],
  listAccountsClosure: [],
  listAllAccountsClosure: [],
  listOrders: [],
  listAllOrders: [],
  listBillProductSummary: [],
  listAllBillProductSummary: [],
  listBillTransactionSummary: [],
  listAllBillTransactionSummary: [],
  listJobSchedule: [],
  listAllJobSchedule: [],
  listJobFailed: [],
  listAllJobFailed: [],
  listTrianSubscription: [],
  listAllTrianSubscription: [],
  listSubscriptionExpiry: [],
  listAllSubscriptionExpiry: [],
  listCollection030: [],
  listAllCollection030: [],
  listCollection3060: [],
  listAllCollection3060: [],
  listCollection6090: [],
  listAllCollection6090: [],
  listCollectionMoreThan90: [],
  listAllCollectionMoreThan90: [],
  listFailedPayment: [],
  listAllFailedPayment: [],
  listPaymentSuspense: [],
  listAllPaymentSuspense: [],
  listAROpsDetails: [],
  listAllAROpsDetails: [],
  listOpenDispute: [],
  listAllOpenDispute: [],
  listOpenWriteOff: [],
  listAllOpenWriteOff: [],
  listUnbilledTransactions: [],
  listAllUnbilledTransactions: [],
  listBillingInvoicing: [],
  listAllBillingInvoicing: [],
  listBillingAR: [],
  listAllBillingAR: [],
  listUsageSummary: [],
  listAllUsageSummary: [],
  listUsageDetail: [],
  listAllUsageDetail: [],
  listUsageReconciliation: [],
  listAllUsageReconciliation: [],
  listRevenueSummary: [],
  listAllRevenueSummary: [],
  listRevenueRecognition: [],
  listAllRevenueRecognition: [],
  listActiveSubscription: [],
  listAllActiveSubscription: [],
  listJobDetail: [],
  listAllJobDetail: [],
  listTaxSummary: [],
  listAllTaxSummary: [],
  listTaxDetail: [],
  listAllTaxDetail: [],
  listSubscriptionContractExpiry: [],
  listAllSubscriptionContractExpiry: [],
  listTrialToPaidSubscription: [],
  listAllTrialToPaidSubscription: [],
  listRevenueForecast: [],
  listAllRevenueForecast: [],
  listMRRForecast: [],
  listAllMRRForecast: [],
  listVolumeAmountByUsageType: [],
  listAllVolumeAmountByUsageType: [],
  listVolumeAmountOperator: [],
  listAllVolumeAmountOperator: [],
  listExcessVoiceTraffic: [],
  listAllExcessVoiceTraffic: [],
  listDistination: [],
  listAllLdDistination: [],
  listServiceReportData: [],
  listAllServiceReportData: [],
  listRawInterconnectSms: [],
  listAllRawInterconnectSms: [],
  listRawInterconnectVoice: [],
  listAllRawInterconnectVoice: [],
  listNewSms: [],
  listAllNewSms: [],
  listCreditNotes: [],
  listAllCreditNotes: [],
  listVoiceCallTypeSummary: [],
  listAllVoiceCallTypeSummary: [],
  listInternetOperatorDataCost: [],
  listAllInternetOperatorDataCost: [],
  listTelecomSummary: [],
  listAllTelecomSummary: [],
  listAccount: [],
  listAllAccount: [],
  listRawFile: [],
  listAllRawFile: [],
  listUsageTransactionDetail: [],
  listAllUsageTransactionDetail: [],
  listBillableServices: [],
  listAllBillableServices: [],
  listAccountingReconciliation: [],
  listAllAccountingReconciliation: [],
  listRawCdrData: [],
  listAllRawCdrData: [],
  listRawSmsData: [],
  listAllRawSmsData: [],
  listNonBroadsoft: [],
  listAllNonBroadsoft: [],
  listServicesForcecast: [],
  listAllServicesForcecast: [],
  employeeCostingPNL: [],
  allEmployeeCostingPNL: [],
  listBillableServicesForecast: [],
  listAllBillableServicesForecast: [],
  listObjectFiles: [],
  listAllObjectFiles: [],
  listProvisioningData: [],
  listAllProvisioningData: [],
  listBillingTracker: [],
  listAllBillingTracker: [],
  employeeCostingYearlyPL: [],
  allEmployeeCostingYearlyPL: [],
  listCommitmentData: [],
  listAllCommitmentData: [],
  listAgeingPayment: [],
  listAllAgeingPayment: [],
  listFileProcessingStats: [],
  listAllFileProcessingStats: [],
  listCommissionsCancellation: [],
  listAllCommissionsCancellation: [],
  listFolioDataExtract: [],
  listAllFolioDataExtract: [],
  listBroadsoftZeroDurationCdrst: [],
  listAllBroadsoftZeroDurationCdrst: [],
  listNonBroadsoftZeroDurationCdrs: [],
  listAllNonBroadsoftZeroDurationCdrs: [],
  listDetailRecords: [],
  listAllDetailRecords: [],
  listBIExtract: [],
  listAllBIExtract: [],
  listRegR00105: [],
  listAllRegR00105: [],
  listRegR00106: [],
  listAllRegR00106: [],
  listRegR00501: [],
  listAllRegR00501: [],
  listRegR00502: [],
  listAllRegR00502: [],
  listRegR00503: [],
  listAllRegR00503: [],
  listRegR00504: [],
  listAllRegR00504: [],
  listRegR00505: [],
  listAllRegR00505: [],
  listRegR00506: [],
  listAllRegR00506: [],
  listRegR00401: [],
  listAllRegR00401: [],
  listRegR00405: [],
  listAllRegR00405: [],
  listRegCreditBureau: [],
  listAllRegCreditBureau: [],
  listTaxExemption: [],
  listAllTaxExemption: [],
  listFonatelReport: [],
  listAllFonatelReport: [],
  listPhcAssetReport: [],
  listAllPhcAssetReport: [],
  listServiceReconReport: [],
  listAllServiceReconReport: [],
  employeeCostingQuarterlyPNL: [],
  allEmployeeCostingQuarterlyPNL: [],
  listUsageConsumptionReport: [],
  listAllUsageConsumptionReport: [],
  listUsageAverageConsumptionReport: [],
  listAllUsageAverageConsumptionReport: [],
  listEnergyTopupReport: [],
  listAllEnergyTopupReport: [],
  listTopUp: [],
  listAllTopUp: [],
  listOrderDetailReport: [],
  listAllOrderDetailReport: [],
  listServiceInvoiceSummaryReport: [],
  listAllServiceInvoiceSummaryReport: [],
  listItemSummaryReport: [],
  listAllItemSummaryReport: [],
  listAssetReport: [],
  listAllAssetReport: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_REPORT_NEW_CUSTOMER_SUCCESS:
      return state.set('listNewCustomer', action.payload);

    case types.GET_ALL_LIST_NEW_CUSTOMER_SUCCESS:
      return state.set('listAllNewCustomer', action.payload);

    case types.GET_LIST_INVOICING_SUCCESS:
      return state.set('listInvoicing', action.payload);

    case types.GET_ALL_LIST_INVOICING_SUCCESS:
      return state.set('listAllInvoicing', action.payload);

    case types.GET_LIST_PAYMENT_SUCCESS:
      return state.set('listPayments', action.payload);

    case types.GET_LIST_ALL_PAYMENT_SUCCESS:
      return state.set('listAllPayments', action.payload);

    case types.GET_LIST_BILLING_SUCCESS:
      return state.set('listBilling', action.payload);

    case types.GET_LIST_ALL_BILLING_SUCCESS:
      return state.set('listAllBilling', action.payload);

    case types.GET_LIST_AGEING_SUMMARY_SUCCESS:
      return state.set('listAgeingSummary', action.payload);

    case types.GET_LIST_ALL_AGEING_SUMMARY_SUCCESS:
      return state.set('listAllAgeingSummary', action.payload);

    case types.GET_REPORT_ACCOUNT_CLOSURE_SUCCESS:
      return state.set('listAccountsClosure', action.payload);

    case types.GET_ALL_LIST_ACCOUNT_CLOSURE_SUCCESS:
      return state.set('listAllAccountsClosure', action.payload);

    case types.GET_LIST_ORDER_SUCCESS:
      return state.set('listOrders', action.payload);

    case types.GET_ALL_LIST_ORDER_SUCCESS:
      return state.set('listAllOrders', action.payload);

    case types.GET_LIST_BILL_PRODUCT_SUMMARY_SUCCESS:
      return state.set('listBillProductSummary', action.payload);

    case types.GET_ALL_LIST_BILL_PRODUCT_SUMMARY_SUCCESS:
      return state.set('listAllBillProductSummary', action.payload);

    case types.GET_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS:
      return state.set('listBillTransactionSummary', action.payload);

    case types.GET_ALL_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS:
      return state.set('listAllBillTransactionSummary', action.payload);

    case types.GET_LIST_JOB_SCHEDULE_SUCCESS:
      return state.set('listJobSchedule', action.payload);

    case types.GET_ALL_LIST_JOB_SCHEDULE_SUCCESS:
      return state.set('listAllJobSchedule', action.payload);

    case types.GET_LIST_JOB_FAILED_SUCCESS:
      return state.set('listJobFailed', action.payload);

    case types.GET_ALL_LIST_JOB_FAILED_SUCCESS:
      return state.set('listAllJobFailed', action.payload);

    case types.GET_LIST_TRIAL_SUBSCRIPTION_SUCCESS:
      return state.set('listTrialSubscription', action.payload);

    case types.GET_ALL_LIST_TRIAL_SUBSCRIPTION_SUCCESS:
      return state.set('listAllTrialSubscription', action.payload);

    case types.GET_LIST_SUBSCRIPTION_EXPIRY_SUCCESS:
      return state.set('listSubscriptionExpiry', action.payload);

    case types.GET_ALL_LIST_SUBSCRIPTION_EXPIRY_SUCCESS:
      return state.set('listAllSubscriptionExpiry', action.payload);

    case types.GET_LIST_COLLECTION_030_SUCCESS:
      return state.set('listCollection030', action.payload);

    case types.GET_ALL_LIST_COLLECTION_030_SUCCESS:
      return state.set('listAllCollection030', action.payload);

    case types.GET_LIST_COLLECTION_3060_SUCCESS:
      return state.set('listCollection3060', action.payload);

    case types.GET_ALL_LIST_COLLECTION_3060_SUCCESS:
      return state.set('listAllCollection3060', action.payload);

    case types.GET_LIST_COLLECTION_6090_SUCCESS:
      return state.set('listCollection6090', action.payload);

    case types.GET_ALL_LIST_COLLECTION_6090_SUCCESS:
      return state.set('listAllCollection6090', action.payload);

    case types.GET_LIST_COLLECTION_MORE_THAN_90_SUCCESS:
      return state.set('listCollectionMoreThan90', action.payload);

    case types.GET_ALL_LIST_COLLECTION_MORE_THAN_90_SUCCESS:
      return state.set('listAllCollectionMoreThan90', action.payload);

    case types.GET_LIST_FAILED_PAYMENT_SUCCESS:
      return state.set('listFailedPayment', action.payload);

    case types.GET_ALL_LIST_FAILED_PAYMENT_SUCCESS:
      return state.set('listAllFailedPayment', action.payload);

    case types.GET_LIST_PAYMENT_SUSPENSE_SUCCESS:
      return state.set('listPaymentSuspense', action.payload);

    case types.GET_ALL_LIST_PAYMENT_SUSPENSE_SUCCESS:
      return state.set('listAllPaymentSuspense', action.payload);

    case types.GET_LIST_AR_OPS_DETAILS_SUCCESS:
      return state.set('listAROpsDetails', action.payload);

    case types.GET_ALL_LIST_AR_OPS_DETAILS_SUCCESS:
      return state.set('listAllAROpsDetails', action.payload);

    case types.GET_LIST_OPEN_DISPUTE_SUCCESS:
      return state.set('listOpenDispute', action.payload);

    case types.GET_ALL_LIST_OPEN_DISPUTE_SUCCESS:
      return state.set('listAllOpenDispute', action.payload);

    case types.GET_LIST_OPEN_WRITE_OFF_SUCCESS:
      return state.set('listOpenWriteOff', action.payload);

    case types.GET_ALL_LIST_OPEN_WRITE_OFF_SUCCESS:
      return state.set('listAllOpenWriteOff', action.payload);

    case types.GET_LIST_BILLED_TRANSACTIONS_SUCCESS:
      return state.set('listUnbilledTransactions', action.payload);

    case types.GET_ALL_LIST_BILLED_TRANSACTIONS_SUCCESS:
      return state.set('listAllUnbilledTransactions', action.payload);

    case types.GET_LIST_BILLING_INVOICING_SUCCESS:
      return state.set('listBillingInvoicing', action.payload);

    case types.GET_ALL_LIST_BILLING_INVOICING_SUCCESS:
      return state.set('listAllBillingInvoicing', action.payload);

    case types.GET_LIST_BILLING_AR_SUCCESS:
      return state.set('listBillingAR', action.payload);

    case types.GET_ALL_LIST_BILLING_AR_SUCCESS:
      return state.set('listAllBillingAR', action.payload);

    case types.GET_LIST_USAGE_SUMMARY_SUCCESS:
      return state.set('listUsageSummary', action.payload);

    case types.GET_ALL_LIST_USAGE_SUMMARY_SUCCESS:
      return state.set('listAllUsageSummary', action.payload);

    case types.GET_LIST_USAGE_DETAIL_SUCCESS:
      return state.set('listUsageDetail', action.payload);

    case types.GET_ALL_LIST_USAGE_DETAIL_SUCCESS:
      return state.set('listAllUsageDetail', action.payload);

    case types.GET_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS:
      return state.set('listUsageReconciliation', action.payload);

    case types.GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS:
      return state.set('listAllUsageReconciliation', action.payload);

    case types.GET_LIST_REVENUE_SUMMARY_SUCCESS:
      return state.set('listRevenueSummary', action.payload);

    case types.GET_ALL_LIST_REVENUE_SUMMARY_SUCCESS:
      return state.set('listAllRevenueSummary', action.payload);

    case types.GET_LIST_REVENUE_RECOGNITION_SUCCESS:
      return state.set('listRevenueRecognition', action.payload);

    case types.GET_ALL_LIST_REVENUE_RECOGNITION_SUCCESS:
      return state.set('listAllRevenueRecognition', action.payload);

    case types.GET_LIST_ACTIVE_SUBSCRIPTION_SUCCESS:
      return state.set('listActiveSubscription', action.payload);

    case types.GET_ALL_LIST_ACTIVE_SUBSCRIPTION_SUCCESS:
      return state.set('listAllActiveSubscription', action.payload);

    case types.GET_LIST_JOB_DETAIL_SUCCESS:
      return state.set('listJobDetail', action.payload);

    case types.GET_ALL_LIST_JOB_DETAIL_SUCCESS:
      return state.set('listAllJobDetail', action.payload);

    case types.GET_LIST_TAX_SUMMARY_SUCCESS:
      return state.set('listTaxSummary', action.payload);

    case types.GET_ALL_LIST_TAX_SUMMARY_SUCCESS:
      return state.set('listAllTaxSummary', action.payload);

    case types.GET_LIST_TAX_DETAIL_SUCCESS:
      return state.set('listTaxDetail', action.payload);

    case types.GET_ALL_LIST_TAX_DETAIL_SUCCESS:
      return state.set('listAllTaxDetail', action.payload);

    case types.GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS:
      return state.set('listSubscriptionContractExpiry', action.payload);

    case types.GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS:
      return state.set('listAllSubscriptionContractExpiry', action.payload);

    case types.GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS:
      return state.set('listTrialToPaidSubscription', action.payload);

    case types.GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS:
      return state.set('listAllTrialToPaidSubscription', action.payload);

    case types.GET_LIST_REVENUE_FORCEAST_SUCCESS:
      return state.set('listRevenueForecast', action.payload);

    case types.GET_ALL_LIST_REVENUE_FORCEAST_SUCCESS:
      return state.set('listAllRevenueForecast', action.payload);

    case types.GET_LIST_MMR_FORCEAST_SUCCESS:
      return state.set('listMRRForecast', action.payload);

    case types.GET_ALL_LIST_MMR_FORCEAST_SUCCESS:
      return state.set('listAllMRRForecast', action.payload);

    case types.GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS:
      return state.set('listVolumeAmountByUsageType', action.payload);

    case types.GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS:
      return state.set('listAllVolumeAmountByUsageType', action.payload);

    case types.GET_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS:
      return state.set('listVolumeAmountOperator', action.payload);

    case types.GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS:
      return state.set('listAllVolumeAmountOperator', action.payload);

    case types.GET_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS:
      return state.set('listExcessVoiceTraffic', action.payload);

    case types.GET_ALL_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS:
      return state.set('listAllExcessVoiceTraffic', action.payload);

    case types.GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS:
      return state.set('listDistination', action.payload);

    case types.GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS:
      return state.set('listAllLdDistination', action.payload);

    case types.GET_LIST_ON_DEMAND_SUCCESS:
      return state.set('listServiceReportData', action.payload);

    case types.GET_ALL_LIST_ON_DEMAND_SUCCESS:
      return state.set('listAllServiceReportData', action.payload);

    case types.GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS:
      return state.set('listRawInterconnectSms', action.payload);

    case types.GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS:
      return state.set('listAllRawInterconnectSms', action.payload);

    case types.GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS:
      return state.set('listRawInterconnectVoice', action.payload);

    case types.GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS:
      return state.set('listAllRawInterconnectVoice', action.payload);

    case types.GET_LIST_NEW_SMS_SUCCESS:
      return state.set('listNewSms', action.payload);

    case types.GET_ALL_LIST_NEW_SMS_SUCCESS:
      return state.set('listAllNewSms', action.payload);

    case types.GET_LIST_CREDIT_NOTES_SUCCESS:
      return state.set('listCreditNotes', action.payload);

    case types.GET_ALL_LIST_CREDIT_NOTES_SUCCESS:
      return state.set('listAllCreditNotes', action.payload);

    case types.GET_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS:
      return state.set('listVoiceCallTypeSummary', action.payload);

    case types.GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS:
      return state.set('listAllVoiceCallTypeSummary', action.payload);

    case types.GET_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS:
      return state.set('listInternetOperatorDataCost', action.payload);

    case types.GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS:
      return state.set('listAllInternetOperatorDataCost', action.payload);

    case types.GET_LIST_TELECOM_SUMMARY_SUCCESS:
      return state.set('listTelecomSummary', action.payload);

    case types.GET_ALL_LIST_TELECOM_SUMMARY_SUCCESS:
      return state.set('listAllTelecomSummary', action.payload);

    case types.GET_LIST_ACCOUNT_SUCCESS:
      return state.set('listAccount', action.payload);

    case types.GET_ALL_LIST_ACCOUNT_SUCCESS:
      return state.set('listAllAccount', action.payload);

    case types.GET_RAW_FILE_SUCCESS:
      return state.set('listRawFile', action.payload);

    case types.GET_ALL_RAW_FILE_SUCCESS:
      return state.set('listAllRawFile', action.payload);

    case types.GET_REPORT_USAGE_TRANSACTION_DETAILS_SUCCESS:
      return state.set('listUsageTransactionDetail', action.payload);

    case types.GET_ALL_LIST_USAGE_TRANSACTION_DETAILS_SUCCESS:
      return state.set('listAllUsageTransactionDetail', action.payload);

    case types.GET_BILLABLE_SERVICES_REPORT_SUCCESS:
      return state.set('listBillableServices', action.payload);

    case types.GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS_SUCCESS:
      return state.set('listAllBillableServices', action.payload);

    case types.GET_ACCOUNTING_RECONCILIATION_REPORT_SUCCESS:
      return state.set('listAccountingReconciliation', action.payload);

    case types.GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS_SUCCESS:
      return state.set('listAllAccountingReconciliation', action.payload);

    case types.GET_RAW_CDR_DATA_REPORT_SUCCESS:
      return state.set('listRawCdrData', action.payload);

    case types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS:
      return state.set('listAllRawCdrData', action.payload);

    case types.GET_RAW_SMS_DATA_REPORT_SUCCESS:
      return state.set('listRawSmsData', action.payload);

    case types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS:
      return state.set('listAllRawSmsData', action.payload);

    case types.GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS:
      return state.set('listNonBroadsoft', action.payload);

    case types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS:
      return state.set('listAllNonBroadsoft', action.payload);

    case types.GET_SERVICES_FORCECAST_SUCCESS:
      return state.set('listServicesForcecast', action.payload);

    case types.GET_ALL_SERVICES_FORCECAST_SUCCESS:
      return state.set('listAllServicesForcecast', action.payload);

    case types.EMPLOYEE_COSTING_PNL_SUCCESS:
      return state.set('employeeCostingPNL', action.payload);

    case types.ALL_EMPLOYEE_COSTING_PNL_SUCCESS:
      return state.set('allEmployeeCostingPNL', action.payload);

    case types.GET_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS:
      return state.set('listBillableServicesForecast', action.payload);

    case types.GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS:
      return state.set('listAllBillableServicesForecast', action.payload);

    case types.GET_OBJECT_FILE_REPORT_SUCCESS:
      return state.set('listObjectFiles', action.payload);

    case types.GET_ALL_OBJECT_FILE_REPORT_SUCCESS:
      return state.set('listAllObjectFiles', action.payload);

    case types.GET_PROVISIONING_DATA_REPORT_SUCCESS:
      return state.set('listProvisioningData', action.payload);

    case types.GET_ALL_PROVISIONING_DATA_REPORT_SUCCESS:
      return state.set('listAllProvisioningData', action.payload);

    case types.BILLING_TRACKER_REPORT_SUCCESS:
      return state.set('listBillingTracker', action.payload);

    case types.GET_ALL_BILLING_TRACKER_REPORT_SUCCESS:
      return state.set('listAllBillingTracker', action.payload);

    case types.EMPLOYEE_COSTING_YEARLY_PL_SUCCESS:
      return state.set('employeeCostingYearlyPL', action.payload);

    case types.ALL_EMPLOYEE_COSTING_YEARLY_PL_SUCCESS:
      return state.set('allEmployeeCostingYearlyPL', action.payload);

    case types.GET_COMMITMENT_REPORT_SUCCESS:
      return state.set('listCommitmentData', action.payload);

    case types.GET_ALL_COMMITMENT_REPORT_SUCCESS:
      return state.set('listAllCommitmentData', action.payload);

    case types.GET_PAYMENT_AGEING_REPORT_SUCCESS:
      return state.set('listAgeingPayment', action.payload);

    case types.GET_ALL_PAYMENT_AGEING_REPORT_SUCCESS:
      return state.set('listAllAgeingPayment', action.payload);

    case types.GET_FILE_PROCESSING_STATS_SUCCESS:
      return state.set('listFileProcessingStats', action.payload);

    case types.GET_ALL_FILE_PROCESSING_STATS_SUCCESS:
      return state.set('listAllFileProcessingStats', action.payload);

    case types.SEARCH_SERVICE_CANCELLATION_EXTRACT_SUCCESS:
      return state.set('listCommissionsCancellation', action.payload);

    case types.SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT_SUCCESS:
      return state.set('listAllCommissionsCancellation', action.payload);

    case types.SEARCH_FOLIO_DATA_EXTRACT_SUCCESS:
      return state.set('listFolioDataExtract', action.payload);

    case types.SEARCH_ALL_FOLIO_DATA_EXTRACT_SUCCESS:
      return state.set('listAllFolioDataExtract', action.payload);

    case types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listBroadsoftZeroDurationCdrst', action.payload);

    case types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listAllBroadsoftZeroDurationCdrst', action.payload);

    case types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listNonBroadsoftZeroDurationCdrs', action.payload);

    case types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS:
      return state.set('listAllNonBroadsoftZeroDurationCdrs', action.payload);

    case types.SEARCH_DETAIL_RECORDS_REPORT_SUCCESS:
      return state.set('listDetailRecords', action.payload);

    case types.SEARCH_ALL_DETAIL_RECORDS_REPORT_SUCCESS:
      return state.set('listAllDetailRecords', action.payload);

    case types.GET_BI_EXTRACT_SUCCESS:
      return state.set('listBIExtract', action.payload);

    case types.GET_ALL_BI_EXTRACT_SUCCESS:
      return state.set('listAllBIExtract', action.payload);

    case types.GET_REG_R00105_REPORT_SUCCESS:
      return state.set('listRegR00105', action.payload);

    case types.GET_ALL_REG_R00105_REPORT_SUCCESS:
      return state.set('listAllRegR00105', action.payload);

    case types.GET_REG_R00106_REPORT_SUCCESS:
      return state.set('listRegR00106', action.payload);

    case types.GET_ALL_REG_R00106_REPORT_SUCCESS:
      return state.set('listAllRegR00106', action.payload);

    case types.GET_REG_R00501_REPORT_SUCCESS:
      return state.set('listRegR00501', action.payload);

    case types.GET_ALL_REG_R00501_REPORT_SUCCESS:
      return state.set('listAllRegR00501', action.payload);

    case types.GET_REG_R00502_REPORT_SUCCESS:
      return state.set('listRegR00502', action.payload);

    case types.GET_ALL_REG_R00502_REPORT_SUCCESS:
      return state.set('listAllRegR00502', action.payload);

    case types.GET_REG_R00503_REPORT_SUCCESS:
      return state.set('listRegR00503', action.payload);

    case types.GET_ALL_REG_R00503_REPORT_SUCCESS:
      return state.set('listAllRegR00503', action.payload);

    case types.GET_REG_R00504_REPORT_SUCCESS:
      return state.set('listRegR00504', action.payload);

    case types.GET_ALL_REG_R00504_REPORT_SUCCESS:
      return state.set('listAllRegR00504', action.payload);

    case types.GET_REG_R00505_REPORT_SUCCESS:
      return state.set('listRegR00505', action.payload);

    case types.GET_ALL_REG_R00505_REPORT_SUCCESS:
      return state.set('listAllRegR00505', action.payload);

    case types.GET_REG_R00506_REPORT_SUCCESS:
      return state.set('listRegR00506', action.payload);

    case types.GET_ALL_REG_R00506_REPORT_SUCCESS:
      return state.set('listAllRegR00506', action.payload);

    case types.GET_REG_R00401_REPORT_SUCCESS:
      return state.set('listRegR00401', action.payload);

    case types.GET_ALL_REG_R00401_REPORT_SUCCESS:
      return state.set('listAllRegR00401', action.payload);

    case types.GET_REG_R00405_REPORT_SUCCESS:
      return state.set('listRegR00405', action.payload);

    case types.GET_ALL_REG_R00405_REPORT_SUCCESS:
      return state.set('listAllRegR00405', action.payload);

    case types.GET_CREDIT_BUREAUR_EPORT_SUCCESS:
      return state.set('listRegCreditBureau', action.payload);

    case types.GET_ALL_CREDIT_BUREAUR_EPORT_SUCCESS:
      return state.set('listAllRegCreditBureau', action.payload);

    case types.GET_TAX_EXEMPTION_REPORT_SUCCESS:
      return state.set('listTaxExemption', action.payload);

    case types.GET_ALL_TAX_EXEMPTION_REPORT_SUCCESS:
      return state.set('listAllTaxExemption', action.payload);

    case types.GET_FONATEL_REPORT_SUCCESS:
      return state.set('listFonatelReport', action.payload);

    case types.GET_ALL_FONATEL_REPORT_SUCCESS:
      return state.set('listAllFonatelReport', action.payload);

    case types.GET_PHC_ASSET_REPORT_SUCCESS:
      return state.set('listPhcAssetReport', action.payload);

    case types.GET_ALL_PHC_ASSET_REPORT_SUCCESS:
      return state.set('listAllPhcAssetReport', action.payload);

    case types.SERVICE_RECON_REPORT_SUCCESS:
      return state.set('listServiceReconReport', action.payload);

    case types.GET_ALL_SERVICE_RECON_REPORT_SUCCESS:
      return state.set('listAllServiceReconReport', action.payload);

    case types.EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS:
      return state.set('employeeCostingQuarterlyPNL', action.payload);

    case types.ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS:
      return state.set('allEmployeeCostingQuarterlyPNL', action.payload);

    case types.GET_USAGE_CONSUMPTION_REPORT_SUCCESS:
      return state.set('listUsageConsumptionReport', action.payload);

    case types.GET_ALL_USAGE_CONSUMPTION_REPORT_SUCCESS:
      return state.set('listAllUsageConsumptionReport', action.payload);

    case types.GET_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS:
      return state.set('listUsageAverageConsumptionReport', action.payload);

    case types.GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS:
      return state.set('listAllUsageAverageConsumptionReport', action.payload);

    case types.GET_ENERGY_TOPUP_REPORT_SUCCESS:
      return state.set('listEnergyTopupReport', action.payload);

    case types.GET_ALL_ENERGY_TOPUP_REPORT_SUCCESS:
      return state.set('listAllEnergyTopupReport', action.payload);

    case types.SEARCH_SUBSCRIPTION_TOP_UP_SUCCESS:
      return state.set('listTopUp', action.payload);

    case types.SEARCH_ALL_SUBSCRIPTION_TOP_UP_SUCCESS:
      return state.set('listAllTopUp', action.payload);

    case types.GET_ORDER_DETAIL_REPORT_SUCCESS:
      return state.set('listOrderDetailReport', action.payload);

    case types.GET_ALL_ORDER_DETAIL_REPORT_SUCCESS:
      return state.set('listAllOrderDetailReport', action.payload);

    case types.GET_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS:
      return state.set('listServiceInvoiceSummaryReport', action.payload);

    case types.GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS:
      return state.set('listAllServiceInvoiceSummaryReport', action.payload);

    case types.GET_ITEM_SUMMARY_REPORT_SUCCESS:
      return state.set('listItemSummaryReport', action.payload);

    case types.GET_ALL_ITEM_SUMMARY_REPORT_SUCCESS:
      return state.set('listAllItemSummaryReport', action.payload);

    case types.GET_ASSET_REPORT_SUCCESS:
      return state.set('listAssetReport', action.payload);

    case types.GET_ALL_ASSET_REPORT_SUCCESS:
      return state.set('listAllAssetReport', action.payload);

    case types.ON_RESET_DEFAUL_DATA:
      return state.set(action.payload, []);
    default:
      return state;
  }
}
