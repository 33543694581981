export const GET_REPORT_NEW_CUSTOMER = 'report/GET_REPORT_NEW_CUSTOMER';
export const GET_REPORT_NEW_CUSTOMER_SUCCESS = 'report/GET_REPORT_NEW_CUSTOMER_SUCCESS';
export const GET_ALL_LIST_NEW_CUSTOMER = 'report/GET_ALL_LIST_NEW_CUSTOMER';
export const GET_ALL_LIST_NEW_CUSTOMER_SUCCESS = 'report/GET_ALL_LIST_NEW_CUSTOMER_SUCCESS';

// Account Closure
export const GET_REPORT_ACCOUNT_CLOSURE = 'report/GET_REPORT_ACCOUNT_CLOSURE';
export const GET_REPORT_ACCOUNT_CLOSURE_SUCCESS = 'report/GET_REPORT_ACCOUNT_CLOSURE_SUCCESS';
export const GET_ALL_LIST_ACCOUNT_CLOSURE = 'report/GET_ALL_LIST_ACCOUNT_CLOSURE';
export const GET_ALL_LIST_ACCOUNT_CLOSURE_SUCCESS = 'report/GET_ALL_LIST_ACCOUNT_CLOSURE_SUCCESS';

// Order
export const GET_LIST_ORDER = 'report/GET_LIST_ORDER';
export const GET_LIST_ORDER_SUCCESS = 'report/GET_LIST_ORDER_SUCCESS';
export const GET_ALL_LIST_ORDER = 'report/GET_ALL_LIST_ORDER';
export const GET_ALL_LIST_ORDER_SUCCESS = 'report/GET_ALL_LIST_ORDER_SUCCESS';

// Subscription Expiry
export const GET_LIST_SUBSCRIPTION_EXPIRY = 'report/GET_LIST_SUBSCRIPTION_EXPIRY';
export const GET_LIST_SUBSCRIPTION_EXPIRY_SUCCESS = 'report/GET_LIST_SUBSCRIPTION_EXPIRY_SUCCESS';
export const GET_ALL_LIST_SUBSCRIPTION_EXPIRY = 'report/GET_ALL_LIST_SUBSCRIPTION_EXPIRY';
export const GET_ALL_LIST_SUBSCRIPTION_EXPIRY_SUCCESS = 'report/GET_ALL_LIST_SUBSCRIPTION_EXPIRY_SUCCESS';

// Trial Subscription
export const GET_LIST_TRIAL_SUBSCRIPTION = 'report/GET_LIST_TRIAL_SUBSCRIPTION';
export const GET_LIST_TRIAL_SUBSCRIPTION_SUCCESS = 'report/GET_LIST_TRIAL_SUBSCRIPTION_SUCCESS';
export const GET_ALL_LIST_TRIAL_SUBSCRIPTION = 'report/GET_ALL_LIST_TRIAL_SUBSCRIPTION';
export const GET_ALL_LIST_TRIAL_SUBSCRIPTION_SUCCESS = 'report/GET_ALL_LIST_TRIAL_SUBSCRIPTION_SUCCESS';

// Invoicing
export const GET_LIST_INVOICING = 'report/GET_LIST_INVOICING';
export const GET_LIST_INVOICING_SUCCESS = 'report/GET_LIST_INVOICING_SUCCESS';
export const GET_ALL_LIST_INVOICING = 'report/GET_ALL_LIST_INVOICING';
export const GET_ALL_LIST_INVOICING_SUCCESS = 'report/GET_ALL_LIST_INVOICING_SUCCESS';

// Payment
export const GET_LIST_PAYMENT = 'report/GET_LIST_PAYMENT';
export const GET_LIST_PAYMENT_SUCCESS = 'report/GET_LIST_PAYMENT_SUCCESS';
export const GET_LIST_ALL_PAYMENT = 'report/GET_ALIST_ALL_PAYMENT';
export const GET_LIST_ALL_PAYMENT_SUCCESS = 'report/GET_ALIST_ALL_PAYMENT_SUCCESS';

// Payment
export const GET_LIST_BILLING = 'report/GET_LIST_BILLING';
export const GET_LIST_BILLING_SUCCESS = 'report/GET_LIST_BILLING_SUCCESS';
export const GET_LIST_ALL_BILLING = 'report/GET_ALIST_ALL_BILLING';
export const GET_LIST_ALL_BILLING_SUCCESS = 'report/GET_ALIST_ALL_BILLING_SUCCESS';

// Ageing Summary
export const GET_LIST_AGEING_SUMMARY = 'report/GET_LIST_AGEING_SUMMARY';
export const GET_LIST_AGEING_SUMMARY_SUCCESS = 'report/GET_LIST_AGEING_SUMMARY_SUCCESS';
export const GET_LIST_ALL_AGEING_SUMMARY = 'report/GET_ALIST_ALL_AGEING_SUMMARY';
export const GET_LIST_ALL_AGEING_SUMMARY_SUCCESS = 'report/GET_ALIST_ALL_AGEING_SUMMARY_SUCCESS';

// Bill Product Summary
export const GET_LIST_BILL_PRODUCT_SUMMARY = 'report/GET_LIST_BILL_PRODUCT_SUMMARY';
export const GET_LIST_BILL_PRODUCT_SUMMARY_SUCCESS = 'report/GET_LIST_BILL_PRODUCT_SUMMARY_SUCCESS';
export const GET_ALL_LIST_BILL_PRODUCT_SUMMARY = 'report/GET_ALL_LIST_BILL_PRODUCT_SUMMARY';
export const GET_ALL_LIST_BILL_PRODUCT_SUMMARY_SUCCESS = 'report/GET_ALL_LIST_BILL_PRODUCT_SUMMARY_SUCCESS';

// Bill Transaction Summary
export const GET_LIST_BILL_TRANSACTION_SUMMARY = 'report/GET_LIST_BILL_TRANSACTION_SUMMARY';
export const GET_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS = 'report/GET_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS';
export const GET_ALL_LIST_BILL_TRANSACTION_SUMMARY = 'report/GET_ALL_LIST_BILL_TRANSACTION_SUMMARY';
export const GET_ALL_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS = 'report/GET_ALL_LIST_BILL_TRANSACTION_SUMMARY_SUCCESS';

// Job Schedule
export const GET_LIST_JOB_SCHEDULE = 'report/GET_LIST_JOB_SCHEDULE';
export const GET_LIST_JOB_SCHEDULE_SUCCESS = 'report/GET_LIST_JOB_SCHEDULE_SUCCESS';
export const GET_ALL_LIST_JOB_SCHEDULE = 'report/GET_ALL_LIST_JOB_SCHEDULE';
export const GET_ALL_LIST_JOB_SCHEDULE_SUCCESS = 'report/GET_ALL_LIST_JOB_SCHEDULE_SUCCESS';

// Job Failed
export const GET_LIST_JOB_FAILED = 'report/GET_LIST_JOB_FAILED';
export const GET_LIST_JOB_FAILED_SUCCESS = 'report/GET_LIST_JOB_FAILED_SUCCESS';
export const GET_ALL_LIST_JOB_FAILED = 'report/GET_ALL_LIST_JOB_FAILED';
export const GET_ALL_LIST_JOB_FAILED_SUCCESS = 'report/GET_ALL_LIST_JOB_FAILED_SUCCESS';

// Collection 0 30
export const GET_LIST_COLLECTION_030 = 'report/GET_LIST_COLLECTION_030';
export const GET_LIST_COLLECTION_030_SUCCESS = 'report/GET_LIST_COLLECTION_030_SUCCESS';
export const GET_ALL_LIST_COLLECTION_030 = 'report/GET_ALL_LIST_COLLECTION_030';
export const GET_ALL_LIST_COLLECTION_030_SUCCESS = 'report/GET_ALL_LIST_COLLECTION_030_SUCCESS';

// Collection 30 60
export const GET_LIST_COLLECTION_3060 = 'report/GET_LIST_COLLECTION_3060';
export const GET_LIST_COLLECTION_3060_SUCCESS = 'report/GET_LIST_COLLECTION_3060_SUCCESS';
export const GET_ALL_LIST_COLLECTION_3060 = 'report/GET_ALL_LIST_COLLECTION_3060';
export const GET_ALL_LIST_COLLECTION_3060_SUCCESS = 'report/GET_ALL_LIST_COLLECTION_3060_SUCCESS';

// Collection 60 90
export const GET_LIST_COLLECTION_6090 = 'report/GET_LIST_COLLECTION_6090';
export const GET_LIST_COLLECTION_6090_SUCCESS = 'report/GET_LIST_COLLECTION_6090_SUCCESS';
export const GET_ALL_LIST_COLLECTION_6090 = 'report/GET_ALL_LIST_COLLECTION_6090';
export const GET_ALL_LIST_COLLECTION_6090_SUCCESS = 'report/GET_ALL_LIST_COLLECTION_6090_SUCCESS';

// Collection more than 90
export const GET_LIST_COLLECTION_MORE_THAN_90 = 'report/GET_LIST_COLLECTION_MORE_THAN_90';
export const GET_LIST_COLLECTION_MORE_THAN_90_SUCCESS = 'report/GET_LIST_COLLECTION_MORE_THAN_90_SUCCESS';
export const GET_ALL_LIST_COLLECTION_MORE_THAN_90 = 'report/GET_ALL_LIST_COLLECTION_MORE_THAN_90';
export const GET_ALL_LIST_COLLECTION_MORE_THAN_90_SUCCESS = 'report/GET_ALL_LIST_COLLECTION_MORE_THAN_90_SUCCESS';

// Failed payment
export const GET_LIST_FAILED_PAYMENT = 'report/GET_LIST_FAILED_PAYMENT';
export const GET_LIST_FAILED_PAYMENT_SUCCESS = 'report/GET_LIST_FAILED_PAYMENT_SUCCESS';
export const GET_ALL_LIST_FAILED_PAYMENT = 'report/GET_ALL_LIST_FAILED_PAYMENT';
export const GET_ALL_LIST_FAILED_PAYMENT_SUCCESS = 'report/GET_ALL_LIST_FAILED_PAYMENT_SUCCESS';

// Payment Suspense
export const GET_LIST_PAYMENT_SUSPENSE = 'report/GET_LIST_PAYMENT_SUSPENSE';
export const GET_LIST_PAYMENT_SUSPENSE_SUCCESS = 'report/GET_LIST_PAYMENT_SUSPENSE_SUCCESS';
export const GET_ALL_LIST_PAYMENT_SUSPENSE = 'report/GET_ALL_LIST_PAYMENT_SUSPENSE';
export const GET_ALL_LIST_PAYMENT_SUSPENSE_SUCCESS = 'report/GET_ALL_LIST_PAYMENT_SUSPENSE_SUCCESS';

// AR Ops Details
export const GET_LIST_AR_OPS_DETAILS = 'report/GET_LIST_AR_OPS_DETAILS';
export const GET_LIST_AR_OPS_DETAILS_SUCCESS = 'report/GET_LIST_AR_OPS_DETAILS_SUCCESS';
export const GET_ALL_LIST_AR_OPS_DETAILS = 'report/GET_ALL_LIST_AR_OPS_DETAILS';
export const GET_ALL_LIST_AR_OPS_DETAILS_SUCCESS = 'report/GET_ALL_LIST_AR_OPS_DETAILS_SUCCESS';

// AR Open Dispute
export const GET_LIST_OPEN_DISPUTE = 'report/GET_LIST_OPEN_DISPUTE';
export const GET_LIST_OPEN_DISPUTE_SUCCESS = 'report/GET_LIST_OPEN_DISPUTE_SUCCESS';
export const GET_ALL_LIST_OPEN_DISPUTE = 'report/GET_ALL_LIST_OPEN_DISPUTE';
export const GET_ALL_LIST_OPEN_DISPUTE_SUCCESS = 'report/GET_ALL_LIST_OPEN_DISPUTE_SUCCESS';

// AR Open Write Off
export const GET_LIST_OPEN_WRITE_OFF = 'report/GET_LIST_OPEN_WRITE_OFF';
export const GET_LIST_OPEN_WRITE_OFF_SUCCESS = 'report/GET_LIST_OPEN_WRITE_OFF_SUCCESS';
export const GET_ALL_LIST_OPEN_WRITE_OFF = 'report/GET_ALL_LIST_OPEN_WRITE_OFF';
export const GET_ALL_LIST_OPEN_WRITE_OFF_SUCCESS = 'report/GET_ALL_LIST_OPEN_WRITE_OFF_SUCCESS';

// AR Unbilled Transactions
export const GET_LIST_BILLED_TRANSACTIONS = 'report/GET_LIST_BILLED_TRANSACTIONS';
export const GET_LIST_BILLED_TRANSACTIONS_SUCCESS = 'report/GET_LIST_BILLED_TRANSACTIONS_SUCCESS';
export const GET_ALL_LIST_BILLED_TRANSACTIONS = 'report/GET_ALL_LIST_BILLED_TRANSACTIONS';
export const GET_ALL_LIST_BILLED_TRANSACTIONS_SUCCESS = 'report/GET_ALL_LIST_BILLED_TRANSACTIONS_SUCCESS';

// AR Billing Invoicing
export const GET_LIST_BILLING_INVOICING = 'report/GET_LIST_BILLING_INVOICING';
export const GET_LIST_BILLING_INVOICING_SUCCESS = 'report/GET_LIST_BILLING_INVOICING_SUCCESS';
export const GET_ALL_LIST_BILLING_INVOICING = 'report/GET_ALL_LIST_BILLING_INVOICING';
export const GET_ALL_LIST_BILLING_INVOICING_SUCCESS = 'report/GET_ALL_LIST_BILLING_INVOICING_SUCCESS';

// AR Billing AR
export const GET_LIST_BILLING_AR = 'report/GET_LIST_BILLING_AR';
export const GET_LIST_BILLING_AR_SUCCESS = 'report/GET_LIST_BILLING_AR_SUCCESS';
export const GET_ALL_LIST_BILLING_AR = 'report/GET_ALL_LIST_BILLING_AR';
export const GET_ALL_LIST_BILLING_AR_SUCCESS = 'report/GET_ALL_LIST_BILLING_AR_SUCCESS';

// Usage Summary
export const GET_LIST_USAGE_SUMMARY = 'report/GET_LIST_USAGE_SUMMARY';
export const GET_LIST_USAGE_SUMMARY_SUCCESS = 'report/GET_LIST_USAGE_SUMMARY_SUCCESS';
export const GET_ALL_LIST_USAGE_SUMMARY = 'report/GET_ALL_LIST_USAGE_SUMMARY';
export const GET_ALL_LIST_USAGE_SUMMARY_SUCCESS = 'report/GET_ALL_USAGE_SUMMARY_SUCCESS';

// Usage Detail
export const GET_LIST_USAGE_DETAIL = 'report/GET_LIST_USAGE_DETAIL';
export const GET_LIST_USAGE_DETAIL_SUCCESS = 'report/GET_USAGE_DETAIL_SUCCESS';
export const GET_ALL_LIST_USAGE_DETAIL = 'report/GET_ALL_LIST_USAGE_DETAIL';
export const GET_ALL_LIST_USAGE_DETAIL_SUCCESS = 'report/GET_ALL_LIST_USAGE_DETAIL_SUCCESS';

// Usage Reconciliation Summary
export const GET_LIST_USAGE_RECONCILIATION_SUMMARY = 'report/GET_LIST_USAGE_RECONCILIATION_SUMMARY';
export const GET_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS = 'report/GET_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS';
export const GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY = 'report/GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY';
export const GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY_SUCCESS = 'report/GET_ALL_USAGE_RECONCILIATION_SUMMARY_SUCCESS';

// Revenue Summary
export const GET_LIST_REVENUE_SUMMARY = 'report/GET_LIST_REVENUE_SUMMARY';
export const GET_LIST_REVENUE_SUMMARY_SUCCESS = 'report/GET_LIST_REVENUE_SUMMARY_SUCCESS';
export const GET_ALL_LIST_REVENUE_SUMMARY = 'report/GET_ALL_LIST_REVENUE_SUMMARY';
export const GET_ALL_LIST_REVENUE_SUMMARY_SUCCESS = 'report/GET_ALL_REVENUE_SUMMARY_SUCCESS';

// Revenue Recognition
export const GET_LIST_REVENUE_RECOGNITION = 'report/GET_LIST_REVENUE_RECOGNITION';
export const GET_LIST_REVENUE_RECOGNITION_SUCCESS = 'report/GET_LIST_REVENUE_RECOGNITION_SUCCESS';
export const GET_ALL_LIST_REVENUE_RECOGNITION = 'report/GET_ALL_LIST_REVENUE_RECOGNITION';
export const GET_ALL_LIST_REVENUE_RECOGNITION_SUCCESS = 'report/GET_ALL_REVENUE_RECOGNITION_SUCCESS';

// Active Subscription
export const GET_LIST_ACTIVE_SUBSCRIPTION = 'report/GET_LIST_ACTIVE_SUBSCRIPTION';
export const GET_LIST_ACTIVE_SUBSCRIPTION_SUCCESS = 'report/GET_LIST_ACTIVE_SUBSCRIPTION_SUCCESS';
export const GET_ALL_LIST_ACTIVE_SUBSCRIPTION = 'report/GET_ALL_LIST_ACTIVE_SUBSCRIPTION';
export const GET_ALL_LIST_ACTIVE_SUBSCRIPTION_SUCCESS = 'report/GET_ALL_ACTIVE_SUBSCRIPTION_SUCCESS';

// Active Subscription
export const GET_LIST_JOB_DETAIL = 'report/GET_LIST_JOB_DETAIL';
export const GET_LIST_JOB_DETAIL_SUCCESS = 'report/GET_LIST_JOB_DETAIL_SUCCESS';
export const GET_ALL_LIST_JOB_DETAIL = 'report/GET_ALL_LIST_JOB_DETAIL';
export const GET_ALL_LIST_JOB_DETAIL_SUCCESS = 'report/GET_ALL_JOB_DETAIL_SUCCESS';

// Tax Summary
export const GET_LIST_TAX_SUMMARY = 'report/GET_LIST_TAX_SUMMARY';
export const GET_LIST_TAX_SUMMARY_SUCCESS = 'report/GET_LIST_TAX_SUMMARY_SUCCESS';
export const GET_ALL_LIST_TAX_SUMMARY = 'report/GET_ALL_LIST_TAX_SUMMARY';
export const GET_ALL_LIST_TAX_SUMMARY_SUCCESS = 'report/GET_ALL_TAX_SUMMARY_SUCCESS';

// Tax Summary
export const GET_LIST_TAX_DETAIL = 'report/GET_LIST_TAX_DETAIL';
export const GET_LIST_TAX_DETAIL_SUCCESS = 'report/GET_LIST_TAX_DETAIL_SUCCESS';
export const GET_ALL_LIST_TAX_DETAIL = 'report/GET_ALL_LIST_TAX_DETAIL';
export const GET_ALL_LIST_TAX_DETAIL_SUCCESS = 'report/GET_ALL_TAX_DETAIL_SUCCESS';

// Get Subscription Contract Expiry
export const GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY = 'report/GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY';
export const GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS = 'report/GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS';
export const GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY = 'report/GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY';
export const GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS = 'report/GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY_SUCCESS';

// Get Trial To Paid Subscription
export const GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION = 'report/GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION';
export const GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS = 'report/GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS';
export const GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION = 'report/GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION';
export const GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS = 'report/GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION_SUCCESS';

// Get Revenue Forecast
export const GET_LIST_REVENUE_FORCEAST = 'report/GET_LIST_REVENUE_FORCEAST';
export const GET_LIST_REVENUE_FORCEAST_SUCCESS = 'report/GET_LIST_REVENUE_FORCEAST_SUCCESS';
export const GET_ALL_LIST_REVENUE_FORCEAST = 'report/GET_ALL_LIST_REVENUE_FORCEAST';
export const GET_ALL_LIST_REVENUE_FORCEAST_SUCCESS = 'report/GET_ALL_LIST_REVENUE_FORCEAST_SUCCESS';

// Get MMR Forecast
export const GET_LIST_MMR_FORCEAST = 'report/GET_LIST_MMR_FORCEAST';
export const GET_LIST_MMR_FORCEAST_SUCCESS = 'report/GET_LIST_MMR_FORCEAST_SUCCESS';
export const GET_ALL_LIST_MMR_FORCEAST = 'report/GET_ALL_LIST_MMR_FORCEAST';
export const GET_ALL_LIST_MMR_FORCEAST_SUCCESS = 'report/GET_ALL_LIST_MMR_FORCEAST_SUCCESS';

// Volume Amount By Usage Type
export const GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE = 'report/GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE';
export const GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS = 'report/GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS';
export const GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE = 'report/GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE';
export const GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS = 'report/GET_ALL_VOLUME_AMOUNT_BY_USAGE_TYPE_SUCCESS';

// Volume Amount Operator
export const GET_LIST_VOLUME_AMOUNT_OPERATOR = 'report/GET_LIST_VOLUME_AMOUNT_OPERATOR';
export const GET_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS = 'report/GET_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS';
export const GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR = 'report/GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR';
export const GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR_SUCCESS = 'report/GET_ALL_VOLUME_AMOUNT_OPERATOR_SUCCESS';

// Excess Voice Traffic Report
export const GET_LIST_EXCESS_VOICE_TRAFFIC = 'report/GET_LIST_EXCESS_VOICE_TRAFFIC';
export const GET_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS = 'report/GET_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS';
export const GET_ALL_LIST_EXCESS_VOICE_TRAFFIC = 'report/GET_ALL_LIST_EXCESS_VOICE_TRAFFIC';
export const GET_ALL_LIST_EXCESS_VOICE_TRAFFIC_SUCCESS = 'report/GET_ALL_EXCESS_VOICE_TRAFFIC_SUCCESS';

// Voice Traffic By Ld Distination
export const GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION = 'report/GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION';
export const GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS = 'report/GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS';
export const GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION = 'report/GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION';
export const GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS = 'report/GET_ALL_VOICE_TRAFFIC_BY_LD_DISTINATION_SUCCESS';

// On Demand
export const GET_LIST_ON_DEMAND = 'report/GET_LIST_ON_DEMAND';
export const GET_LIST_ON_DEMAND_SUCCESS = 'report/GET_LIST_ON_DEMAND_SUCCESS';
export const GET_ALL_LIST_ON_DEMAND = 'report/GET_ALL_LIST_ON_DEMAND';
export const GET_ALL_LIST_ON_DEMAND_SUCCESS = 'report/GET_ALL_ON_DEMAND_SUCCESS';

// Raw Interconnect Sms Report Data
export const GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA = 'report/GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA';
export const GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS = 'report/GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS';
export const GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA = 'report/GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA';
export const GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS = 'report/GET_ALL_RAW_INTERCONNECT_SMS_REPORT_DATA_SUCCESS';

// New Sms Report Data
export const GET_LIST_NEW_SMS = 'report/GET_LIST_NEW_SMS';
export const GET_LIST_NEW_SMS_SUCCESS = 'report/GET_LIST_NEW_SMS_SUCCESS';
export const GET_ALL_LIST_NEW_SMS = 'report/GET_ALL_LIST_NEW_SMS';
export const GET_ALL_LIST_NEW_SMS_SUCCESS = 'report/GET_ALL_NEW_SMS_SUCCESS';

// Raw Interconnect Voice Report Data
export const GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA = 'report/GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA';
export const GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS =
  'report/GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS';
export const GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA = 'report/GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA';
export const GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS =
  'report/GET_ALL_RAW_INTERCONNECT_VOICE_REPORT_DATA_SUCCESS';

// Raw Interconnect Voice Report Data
export const GET_LIST_CREDIT_NOTES = 'report/GET_LIST_CREDIT_NOTES';
export const GET_LIST_CREDIT_NOTES_SUCCESS = 'report/GET_LIST_CREDIT_NOTES_SUCCESS';
export const GET_ALL_LIST_CREDIT_NOTES = 'report/GET_ALL_LIST_CREDIT_NOTES';
export const GET_ALL_LIST_CREDIT_NOTES_SUCCESS = 'report/GET_ALL_CREDIT_NOTES_SUCCESS';

// Voice Call Type Summary Report
export const GET_LIST_VOICE_CALL_TYPE_SUMMARY = 'report/GET_LIST_VOICE_CALL_TYPE_SUMMARY';
export const GET_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS = 'report/GET_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS';
export const GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY = 'report/GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY';
export const GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY_SUCCESS = 'report/GET_ALL_VOICE_CALL_TYPE_SUMMARY_SUCCESS';

// Internet Operator Data Cost
export const GET_LIST_INTERNET_OPERATOR_DATA_COST = 'report/GET_LIST_INTERNET_OPERATOR_DATA_COST';
export const GET_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS = 'report/GET_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS';
export const GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST = 'report/GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST';
export const GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST_SUCCESS = 'report/GET_ALL_INTERNET_OPERATOR_DATA_COST_SUCCESS';

// Get Telecom Summary
export const GET_LIST_TELECOM_SUMMARY = 'report/GET_LIST_TELECOM_SUMMARY';
export const GET_LIST_TELECOM_SUMMARY_SUCCESS = 'report/GET_LIST_TELECOM_SUMMARY_SUCCESS';
export const GET_ALL_LIST_TELECOM_SUMMARY = 'report/GET_ALL_LIST_TELECOM_SUMMARY';
export const GET_ALL_LIST_TELECOM_SUMMARY_SUCCESS = 'report/GET_ALL_TELECOM_SUMMARY_SUCCESS';

// Get Account report
export const GET_LIST_ACCOUNT = 'report/GET_LIST_ACCOUNT';
export const GET_LIST_ACCOUNT_SUCCESS = 'report/GET_LIST_ACCOUNT_SUCCESS';
export const GET_ALL_LIST_ACCOUNT = 'report/GET_ALL_LIST_ACCOUNT';
export const GET_ALL_LIST_ACCOUNT_SUCCESS = 'report/GET_ALL_ACCOUNT_SUCCESS';

// Get Raw File
export const GET_RAW_FILE = 'report/GET_RAW_FILE';
export const GET_RAW_FILE_SUCCESS = 'report/GET_RAW_FILE_SUCCESS';
export const GET_ALL_RAW_FILE = 'report/GET_ALL_RAW_FILE';
export const GET_ALL_RAW_FILE_SUCCESS = 'report/GET_ALL_RAW_FILE_SUCCESS';

export const GET_REPORT_USAGE_TRANSACTION_DETAILS = 'report/GET_REPORT_USAGE_TRANSACTION_DETAILS';
export const GET_REPORT_USAGE_TRANSACTION_DETAILS_SUCCESS = 'report/GET_REPORT_USAGE_TRANSACTION_DETAILS_SUCCESS';
export const GET_ALL_LIST_USAGE_TRANSACTION_DETAILS = 'report/GET_ALL_LIST_USAGE_TRANSACTION_DETAILS';
export const GET_ALL_LIST_USAGE_TRANSACTION_DETAILS_SUCCESS = 'report/GET_ALL_LIST_USAGE_TRANSACTION_DETAILS_SUCCESS';

export const GET_BILLABLE_SERVICES_REPORT = 'report/GET_BILLABLE_SERVICES_REPORT';
export const GET_BILLABLE_SERVICES_REPORT_SUCCESS = 'report/GET_BILLABLE_SERVICES_REPORT_SUCCESS';
export const GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS = 'report/GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS';
export const GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS_SUCCESS = 'report/GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS_SUCCESS';

export const GET_ACCOUNTING_RECONCILIATION_REPORT = 'report/GET_ACCOUNTING_RECONCILIATION_REPORT';
export const GET_ACCOUNTING_RECONCILIATION_REPORT_SUCCESS = 'report/GET_ACCOUNTING_RECONCILIATION_REPORT_SUCCESS';
export const GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS = 'report/GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS';
export const GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS_SUCCESS =
  'report/GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS_SUCCESS';

export const GET_RAW_CDR_DATA_REPORT = 'report/GET_RAW_CDR_DATA_REPORT';
export const GET_RAW_CDR_DATA_REPORT_SUCCESS = 'report/GET_RAW_CDR_DATA_REPORT_SUCCESS';
export const GET_ALL_RAW_CDR_DATA_REPORT_DETAILS = 'report/GET_ALL_RAW_CDR_DATA_REPORT_DETAILS';
export const GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS = 'report/GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS';

export const GET_RAW_SMS_DATA_REPORT = 'report/GET_RAW_SMS_DATA_REPORT';
export const GET_RAW_SMS_DATA_REPORT_SUCCESS = 'report/GET_RAW_SMS_DATA_REPORT_SUCCESS';
export const GET_ALL_RAW_SMS_DATA_REPORT_DETAILS = 'report/GET_ALL_RAW_SMS_DATA_REPORT_DETAILS';
export const GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS = 'report/GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS';

export const GET_NON_BROAD_SOFT_RAW_CDR_DATA = 'report/GET_NON_BROAD_SOFT_RAW_CDR_DATA';
export const GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS = 'report/GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS';
export const GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA = 'report/GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA';
export const GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS = 'report/GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS';

export const GET_SERVICES_FORCECAST = 'report/GET_SERVICES_FORCECAST';
export const GET_SERVICES_FORCECAST_SUCCESS = 'report/GET_SERVICES_FORCECAST_SUCCESS';
export const GET_ALL_SERVICES_FORCECAST = 'report/GET_ALL_SERVICES_FORCECAST';
export const GET_ALL_SERVICES_FORCECAST_SUCCESS = 'report/GET_ALL_SERVICES_FORCECAST_SUCCESS';

export const EMPLOYEE_COSTING_PNL = 'report/EMPLOYEE_COSTING_PNL';
export const EMPLOYEE_COSTING_PNL_SUCCESS = 'report/EMPLOYEE_COSTING_PNL_SUCCESS';
export const ALL_EMPLOYEE_COSTING_PNL = 'report/ALL_EMPLOYEE_COSTING_PNL';
export const ALL_EMPLOYEE_COSTING_PNL_SUCCESS = 'report/ALL_EMPLOYEE_COSTING_PNL_SUCCESS';

export const GET_BILLABLE_SERVICES_FORECAST_REPORT = 'report/GET_BILLABLE_SERVICES_FORECAST_REPORT';
export const GET_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS = 'report/GET_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS';
export const GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT = 'report/GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT';
export const GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS = 'report/GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT_SUCCESS';

export const GET_OBJECT_FILE_REPORT = 'report/GET_OBJECT_FILE_REPORT';
export const GET_OBJECT_FILE_REPORT_SUCCESS = 'report/GET_OBJECT_FILE_REPORT_SUCCESS';
export const GET_ALL_OBJECT_FILE_REPORT = 'report/GET_ALL_OBJECT_FILE_REPORT';
export const GET_ALL_OBJECT_FILE_REPORT_SUCCESS = 'report/GET_ALL_OBJECT_FILE_REPORT_SUCCESS';

export const GET_PROVISIONING_DATA_REPORT = 'report/GET_PROVISIONING_DATA_REPORT';
export const GET_PROVISIONING_DATA_REPORT_SUCCESS = 'report/GET_PROVISIONING_DATA_REPORT_SUCCESS';
export const GET_ALL_PROVISIONING_DATA_REPORT = 'report/GET_ALL_PROVISIONING_DATA_REPORT';
export const GET_ALL_PROVISIONING_DATA_REPORT_SUCCESS = 'report/GET_ALL_PROVISIONING_DATA_REPORT_SUCCESS';
export const BILLING_TRACKER_REPORT = 'report/BILLING_TRACKER_REPORT';
export const BILLING_TRACKER_REPORT_SUCCESS = 'report/BILLING_TRACKER_REPORT_SUCCESS';
export const GET_ALL_BILLING_TRACKER_REPORT = 'report/GET_ALL_BILLING_TRACKER_REPORT';
export const GET_ALL_BILLING_TRACKER_REPORT_SUCCESS = 'report/GET_ALL_BILLING_TRACKER_REPORT_SUCCESS';

export const EMPLOYEE_COSTING_YEARLY_PL = 'report/EMPLOYEE_COSTING_YEARLY_PL';
export const EMPLOYEE_COSTING_YEARLY_PL_SUCCESS = 'report/EMPLOYEE_COSTING_YEARLY_PL_SUCCESS';
export const ALL_EMPLOYEE_COSTING_YEARLY_PL = 'report/ALL_EMPLOYEE_COSTING_YEARLY_PL';
export const ALL_EMPLOYEE_COSTING_YEARLY_PL_SUCCESS = 'report/ALL_EMPLOYEE_COSTING_YEARLY_PL_SUCCESS';

export const GET_COMMITMENT_REPORT = 'report/GET_COMMITMENT_REPORT';
export const GET_COMMITMENT_REPORT_SUCCESS = 'report/GET_COMMITMENT_REPORT_SUCCESS';
export const GET_ALL_COMMITMENT_REPORT = 'report/GET_ALL_COMMITMENT_REPORT';
export const GET_ALL_COMMITMENT_REPORT_SUCCESS = 'report/GET_ALL_COMMITMENT_REPORT_SUCCESS';

export const GET_PAYMENT_AGEING_REPORT = 'report/GET_PAYMENT_AGEING_REPORT';
export const GET_PAYMENT_AGEING_REPORT_SUCCESS = 'report/GET_PAYMENT_AGEING_REPORT_SUCCESS';
export const GET_ALL_PAYMENT_AGEING_REPORT = 'report/GET_ALL_PAYMENT_AGEING_REPORT';
export const GET_ALL_PAYMENT_AGEING_REPORT_SUCCESS = 'report/GET_ALL_PAYMENT_AGEING_REPORT_SUCCESS';

export const GET_FILE_PROCESSING_STATS = 'report/GET_FILE_PROCESSING_STATS';
export const GET_FILE_PROCESSING_STATS_SUCCESS = 'report/GET_FILE_PROCESSING_STATS_SUCCESS';
export const GET_ALL_FILE_PROCESSING_STATS = 'report/GET_ALL_FILE_PROCESSING_STATS';
export const GET_ALL_FILE_PROCESSING_STATS_SUCCESS = 'report/GET_ALL_FILE_PROCESSING_STATS_SUCCESS';

export const SEARCH_SERVICE_CANCELLATION_EXTRACT = 'report/SEARCH_SERVICE_CANCELLATION_EXTRACT';
export const SEARCH_SERVICE_CANCELLATION_EXTRACT_SUCCESS = 'report/SEARCH_SERVICE_CANCELLATION_EXTRACT_SUCCESS';
export const SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT = 'report/SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT';
export const SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT_SUCCESS = 'report/SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT_SUCCESS';

export const SEARCH_FOLIO_DATA_EXTRACT = 'report/SEARCH_FOLIO_DATA_EXTRACT';
export const SEARCH_FOLIO_DATA_EXTRACT_SUCCESS = 'report/SEARCH_FOLIO_DATA_EXTRACT_SUCCESS';
export const SEARCH_ALL_FOLIO_DATA_EXTRACT = 'report/SEARCH_ALL_FOLIO_DATA_EXTRACT';
export const SEARCH_ALL_FOLIO_DATA_EXTRACT_SUCCESS = 'report/SEARCH_ALL_FOLIO_DATA_EXTRACT_SUCCESS';

export const GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'report/GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS = 'report/GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';
export const GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'report/GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'report/GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';

export const GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'report/GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'report/GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';
export const GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'report/GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'report/GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';

export const ON_RESET_DEFAUL_DATA = 'report/ON_RESET_DEFAUL_DATA';
export const SEARCH_DETAIL_RECORDS_REPORT = 'report/SEARCH_DETAIL_RECORDS_REPORT';
export const SEARCH_DETAIL_RECORDS_REPORT_SUCCESS = 'report/SEARCH_DETAIL_RECORDS_REPORT_SUCCESS';
export const SEARCH_ALL_DETAIL_RECORDS_REPORT = 'report/SEARCH_ALL_DETAIL_RECORDS_REPORT';
export const SEARCH_ALL_DETAIL_RECORDS_REPORT_SUCCESS = 'report/SEARCH_ALL_DETAIL_RECORDS_REPORT_SUCCESS';

export const GET_BI_EXTRACT = 'report/GET_BI_EXTRACT';
export const GET_BI_EXTRACT_SUCCESS = 'report/GET_BI_EXTRACT_SUCCESS';
export const GET_ALL_BI_EXTRACT = 'report/GET_ALL_BI_EXTRACT';
export const GET_ALL_BI_EXTRACT_SUCCESS = 'report/GET_ALL_BI_EXTRACT_SUCCESS';

export const GET_REG_R00105_REPORT = 'report/GET_REG_R00105_REPORT';
export const GET_REG_R00105_REPORT_SUCCESS = 'report/GET_REG_R00105_REPORT_SUCCESS';
export const GET_ALL_REG_R00105_REPORT = 'report/GET_ALL_REG_R00105_REPORT';
export const GET_ALL_REG_R00105_REPORT_SUCCESS = 'report/GET_ALL_REG_R00105_REPORT_SUCCESS';

export const GET_REG_R00106_REPORT = 'report/GET_REG_R00106_REPORT';
export const GET_REG_R00106_REPORT_SUCCESS = 'report/GET_REG_R00106_REPORT_SUCCESS';
export const GET_ALL_REG_R00106_REPORT = 'report/GET_ALL_REG_R00106_REPORT';
export const GET_ALL_REG_R00106_REPORT_SUCCESS = 'report/GET_ALL_REG_R00106_REPORT_SUCCESS';

export const GET_REG_R00501_REPORT = 'report/GET_REG_R00501_REPORT';
export const GET_REG_R00501_REPORT_SUCCESS = 'report/GET_REG_R00501_REPORT_SUCCESS';
export const GET_ALL_REG_R00501_REPORT = 'report/GET_ALL_REG_R00501_REPORT';
export const GET_ALL_REG_R00501_REPORT_SUCCESS = 'report/GET_ALL_REG_R00501_REPORT_SUCCESS';

export const GET_REG_R00502_REPORT = 'report/GET_REG_R00502_REPORT';
export const GET_REG_R00502_REPORT_SUCCESS = 'report/GET_REG_R00502_REPORT_SUCCESS';
export const GET_ALL_REG_R00502_REPORT = 'report/GET_ALL_REG_R00502_REPORT';
export const GET_ALL_REG_R00502_REPORT_SUCCESS = 'report/GET_ALL_REG_R00502_REPORT_SUCCESS';

export const GET_REG_R00503_REPORT = 'report/GET_REG_R00503_REPORT';
export const GET_REG_R00503_REPORT_SUCCESS = 'report/GET_REG_R00503_REPORT_SUCCESS';
export const GET_ALL_REG_R00503_REPORT = 'report/GET_ALL_REG_R00503_REPORT';
export const GET_ALL_REG_R00503_REPORT_SUCCESS = 'report/GET_ALL_REG_R00503_REPORT_SUCCESS';

export const GET_REG_R00504_REPORT = 'report/GET_REG_R00504_REPORT';
export const GET_REG_R00504_REPORT_SUCCESS = 'report/GET_REG_R00504_REPORT_SUCCESS';
export const GET_ALL_REG_R00504_REPORT = 'report/GET_ALL_REG_R00504_REPORT';
export const GET_ALL_REG_R00504_REPORT_SUCCESS = 'report/GET_ALL_REG_R00504_REPORT_SUCCESS';

export const GET_REG_R00505_REPORT = 'report/GET_REG_R00505_REPORT';
export const GET_REG_R00505_REPORT_SUCCESS = 'report/GET_REG_R00505_REPORT_SUCCESS';
export const GET_ALL_REG_R00505_REPORT = 'report/GET_ALL_REG_R00505_REPORT';
export const GET_ALL_REG_R00505_REPORT_SUCCESS = 'report/GET_ALL_REG_R00505_REPORT_SUCCESS';

export const GET_REG_R00506_REPORT = 'report/GET_REG_R00506_REPORT';
export const GET_REG_R00506_REPORT_SUCCESS = 'report/GET_REG_R00506_REPORT_SUCCESS';
export const GET_ALL_REG_R00506_REPORT = 'report/GET_ALL_REG_R00506_REPORT';
export const GET_ALL_REG_R00506_REPORT_SUCCESS = 'report/GET_ALL_REG_R00506_REPORT_SUCCESS';

export const GET_REG_R00401_REPORT = 'report/GET_REG_R00401_REPORT';
export const GET_REG_R00401_REPORT_SUCCESS = 'report/GET_REG_R00401_REPORT_SUCCESS';
export const GET_ALL_REG_R00401_REPORT = 'report/GET_ALL_REG_R00401_REPORT';
export const GET_ALL_REG_R00401_REPORT_SUCCESS = 'report/GET_ALL_REG_R00401_REPORT_SUCCESS';

export const GET_REG_R00405_REPORT = 'report/GET_REG_R00405_REPORT';
export const GET_REG_R00405_REPORT_SUCCESS = 'report/GET_REG_R00405_REPORT_SUCCESS';
export const GET_ALL_REG_R00405_REPORT = 'report/GET_ALL_REG_R00405_REPORT';
export const GET_ALL_REG_R00405_REPORT_SUCCESS = 'report/GET_ALL_REG_R00405_REPORT_SUCCESS';

export const GET_CREDIT_BUREAUR_EPORT = 'report/GET_CREDIT_BUREAUR_EPORT';
export const GET_CREDIT_BUREAUR_EPORT_SUCCESS = 'report/GET_CREDIT_BUREAUR_EPORT_SUCCESS';
export const GET_ALL_CREDIT_BUREAUR_EPORT = 'report/GET_ALL_CREDIT_BUREAUR_EPORT';
export const GET_ALL_CREDIT_BUREAUR_EPORT_SUCCESS = 'report/GET_ALL_CREDIT_BUREAUR_EPORT_SUCCESS';

export const GET_DATA_SERVICE_REPORT_DETAILS = 'report/GET_DATA_SERVICE_REPORT_DETAILS';
export const GET_ALL_DATA_SERVICE_REPORT_DETAILS = 'report/GET_ALL_DATA_SERVICE_REPORT_DETAILS';
export const PUSH_KPI_EXTRACT = 'report/PUSH_KPI_EXTRACT';

export const GET_TAX_EXEMPTION_REPORT = 'report/GET_TAX_EXEMPTION_REPORT';
export const GET_TAX_EXEMPTION_REPORT_SUCCESS = 'report/GET_TAX_EXEMPTION_REPORT_SUCCESS';
export const GET_ALL_TAX_EXEMPTION_REPORT = 'report/GET_ALL_TAX_EXEMPTION_REPORT';
export const GET_ALL_TAX_EXEMPTION_REPORT_SUCCESS = 'report/GET_ALL_TAX_EXEMPTION_REPORT_SUCCESS';

export const GET_FONATEL_REPORT = 'report/GET_FONATEL_REPORT';
export const GET_FONATEL_REPORT_SUCCESS = 'report/GET_FONATEL_REPORT_SUCCESS';
export const GET_ALL_FONATEL_REPORT = 'report/GET_ALL_FONATEL_REPORT';
export const GET_ALL_FONATEL_REPORT_SUCCESS = 'report/GET_ALL_FONATEL_REPORT_SUCCESS';

export const GET_PHC_ASSET_REPORT = 'report/GET_PHC_ASSET_REPORT';
export const GET_PHC_ASSET_REPORT_SUCCESS = 'report/GET_PHC_ASSET_REPORT_SUCCESS';
export const GET_ALL_PHC_ASSET_REPORT = 'report/GET_ALL_PHC_ASSET_REPORT';
export const GET_ALL_PHC_ASSET_REPORT_SUCCESS = 'report/GET_ALL_PHC_ASSET_REPORT_SUCCESS';

export const SERVICE_RECON_REPORT = 'report/SERVICE_RECON_REPORT';
export const SERVICE_RECON_REPORT_SUCCESS = 'report/SERVICE_RECON_REPORT_SUCCESS';
export const GET_ALL_SERVICE_RECON_REPORT = 'report/GET_ALL_SERVICE_RECON_REPORT';
export const GET_ALL_SERVICE_RECON_REPORT_SUCCESS = 'report/GET_ALL_SERVICE_RECON_REPORT_SUCCESS';

export const EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT = 'report/EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT';
export const EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS = 'report/EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS';
export const ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT = 'report/ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT';
export const ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS = 'report/ALL_EMPLOYEE_COSTING_QUARTERLY_PNL_REPORT_SUCCESS';

export const GET_USAGE_CONSUMPTION_REPORT = 'report/GET_USAGE_CONSUMPTION_REPORT';
export const GET_USAGE_CONSUMPTION_REPORT_SUCCESS = 'report/GET_USAGE_CONSUMPTION_REPORT_SUCCESS';
export const GET_ALL_USAGE_CONSUMPTION_REPORT = 'report/GET_ALL_USAGE_CONSUMPTION_REPORT';
export const GET_ALL_USAGE_CONSUMPTION_REPORT_SUCCESS = 'report/GET_ALL_USAGE_CONSUMPTION_REPORT_SUCCESS';

export const GET_USAGE_AVERAGE_CONSUMPTION_REPORT = 'report/GET_USAGE_AVERAGE_CONSUMPTION_REPORT';
export const GET_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS = 'report/GET_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS';
export const GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT = 'report/GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT';
export const GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS = 'report/GET_ALL_USAGE_AVERAGE_CONSUMPTION_REPORT_SUCCESS';

export const GET_ENERGY_TOPUP_REPORT = 'report/GET_ENERGY_TOPUP_REPORT';
export const GET_ENERGY_TOPUP_REPORT_SUCCESS = 'report/GET_ENERGY_TOPUP_REPORT_SUCCESS';
export const GET_ALL_ENERGY_TOPUP_REPORT = 'report/GET_ALL_ENERGY_TOPUP_REPORT';
export const GET_ALL_ENERGY_TOPUP_REPORT_SUCCESS = 'report/GET_ALL_ENERGY_TOPUP_REPORT_SUCCESS';

export const SEARCH_SUBSCRIPTION_TOP_UP = 'report/SEARCH_SUBSCRIPTION_TOP_UP';
export const SEARCH_SUBSCRIPTION_TOP_UP_SUCCESS = 'report/SEARCH_SUBSCRIPTION_TOP_UP_SUCCESS';
export const SEARCH_ALL_SUBSCRIPTION_TOP_UP = 'report/SEARCH_ALL_SUBSCRIPTION_TOP_UP';
export const SEARCH_ALL_SUBSCRIPTION_TOP_UP_SUCCESS = 'report/SEARCH_ALL_SUBSCRIPTION_TOP_UP_SUCCESS';

export const GET_ORDER_DETAIL_REPORT = 'report/GET_ORDER_DETAIL_REPORT';
export const GET_ORDER_DETAIL_REPORT_SUCCESS = 'report/GET_ORDER_DETAIL_REPORT_SUCCESS';
export const GET_ALL_ORDER_DETAIL_REPORT = 'report/GET_ALL_ORDER_DETAIL_REPORT';
export const GET_ALL_ORDER_DETAIL_REPORT_SUCCESS = 'report/GET_ALL_ORDER_DETAIL_REPORT_SUCCESS';

export const GET_SERVICE_INVOICE_SUMMARY_REPORT = 'report/GET_SERVICE_INVOICE_SUMMARY_REPORT';
export const GET_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS = 'report/GET_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS';
export const GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT = 'report/GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT';
export const GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS = 'report/GET_ALL_SERVICE_INVOICE_SUMMARY_REPORT_SUCCESS';

export const GET_ITEM_SUMMARY_REPORT = 'report/GET_ITEM_SUMMARY_REPORT';
export const GET_ITEM_SUMMARY_REPORT_SUCCESS = 'report/GET_ITEM_SUMMARY_REPORT_SUCCESS';
export const GET_ALL_ITEM_SUMMARY_REPORT = 'report/GET_ALL_ITEM_SUMMARY_REPORT';
export const GET_ALL_ITEM_SUMMARY_REPORT_SUCCESS = 'report/GET_ALL_ITEM_SUMMARY_REPORT_SUCCESS';

export const GET_ASSET_REPORT = 'report/GET_ASSET_REPORT';
export const GET_ASSET_REPORT_SUCCESS = 'report/GET_ASSET_REPORT_SUCCESS';
export const GET_ALL_ASSET_REPORT = 'report/GET_ALL_ASSET_REPORT';
export const GET_ALL_ASSET_REPORT_SUCCESS = 'report/GET_ALL_ASSET_REPORT_SUCCESS';
