import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionGetTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTaxconfig });
};

const checkPermissionCreateTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createTaxconfig });
};

const checkPermissionModifyTaxConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyTaxconfig });
};

const checkPermissionCreateUomConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createUomConfig });
};

const checkPermissionModifyUomConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyUomConfig });
};

const checkPermissionGetUomConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUomConfig });
};

const checkPermissionCreateConfigUsageType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createConfigUsageType });
};

const checkPermissionModifyConfigUsageType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyConfigUsageType });
};

const checkPermissionGetConfigUsageType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getConfigUsageType });
};

const checkPermissionCreateTopupOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_TOPUP_SYSTEM_OFFER_CONFIG });
};

const checkPermissionModifyTopupOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_TOPUP_SYSTEM_OFFER_CONFIG });
};

const checkPermissionSearchTopupOffer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_TOPUP_SYSTEM_OFFER_CONFIG });
};

const checkPermissionCreateZoneUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_ZONE_UNIT_CONFIG });
};

const checkPermissionModifyZoneUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_ZONE_UNIT_CONFIG });
};

const checkPermissionViewZoneUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_ZONE_UNIT_CONFIG });
};

const checkPermissionSearchRatedUsage = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_RATED_USAGE });
};

const checkPermissionCreateConfigCountryCodes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_COUNTRY_CODE });
};

const checkPermissionModifyConfigCountryCodes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_COUNTRY_CODE });
};

const checkPermissionSearchConfigCountryCodes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_COUNTRY_CODE });
};

const checkPermissionCreateRateUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_RATE_UNIT_CONFIG });
};

const checkPermissionModifyRateUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_RATE_UNIT_CONFIG });
};

const checkPermissionViewRateUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_RATE_UNIT_CONFIG });
};

const checkPermissionCreateRateUnitMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_RATE_UNIT_MAP_CONFIG });
};

const checkPermissionModifyRateUnitMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_RATE_UNIT_CONFIG });
};

const checkPermissionGetRateUnitMapConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_RATE_UNIT_MAP_CONFIG });
};

const checkPermissionCreateRegulatoryProductCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_REGULATORY_PRODUCT_CODE });
};

const checkPermissionModifyRegulatoryProductCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_REGULATORY_PRODUCT_CODE });
};

const checkPermissionViewRegulatoryProductCode = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_REGULATORY_PRODUCT_CODE });
};

const checkPermissionCreateCurrencyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_CURRENCY_CONFIG });
};

const checkPermissionModifyCurrencyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_CURRENCY_CONFIG });
};

const checkPermissionViewCurrencyConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_CURRENCY_CONFIG });
};

const checkPermissionCreateGrantsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_GRANTS_CONFIG });
};

const checkPermissionModifyGrantsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_GRANTS_CONFIG });
};

const checkPermissionViewGrantsConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_GRANTS_CONFIG });
};

const checkPermissionCreateAccumulatorConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_ACCUMULATOR_CONFIG });
};

const checkPermissionModifyAccumulatorConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_ACCUMULATOR_CONFIG });
};

const checkPermissionViewAccumulatorConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_ACCUMULATOR_CONFIG });
};

const checkPermissionCreateTimeUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.CREATE_TIME_UNIT_CONFIG });
};

const checkPermissionModifyTimeUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.MODIFY_TIME_UNIT_CONFIG });
};

const checkPermissionViewTimeUnitConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.VIEW_TIME_UNIT_CONFIG });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeGetTaxConfig = 0;
  let modeCreateTaxConfig = 0;
  let modeModifyTaxConfig = 0;

  let modeGetUomConfig = 0;
  let modeCreateUomConfig = 0;
  let modeModifyUomConfig = 0;

  let modeGetConfigUsageType = 0;
  let modeCreateConfigUsageType = 0;
  let modeModifyConfigUsageType = 0;

  let modeSearchTopupOffer = 0;
  let modeCreateTopupOffer = 0;
  let modeModifyTopupOffer = 0;

  let modeViewZoneUnit = 0;
  let modeCreateZoneUnit = 0;
  let modeModifyZoneUnit = 0;
  let modeSearchRatedUsage = 0;

  let modeCreateConfigCountryCodes = 0;
  let modeModifyConfigCountryCodes = 0;
  let modeSearchConfigCountryCodes = 0;

  let modeCreateRateUnitConfig = 0;
  let modeModifyRateUnitConfig = 0;
  let modeViewRateUnitConfig = 0;

  let modeCreateRateUnitMapConfig = 0;
  let modeModifyRateUnitMapConfig = 0;
  let modeGetRateUnitMapConfig = 0;

  let modeCreateRegulatoryProductCode = 0;
  let modeModifyRegulatoryProductCode = 0;
  let modeViewRegulatoryProductCode = 0;

  let modeCreateCurrencyConfig = 0;
  let modeModifyCurrencyConfig = 0;
  let modeViewCurrencyConfig = 0;

  let modeCreateGrantsConfig = 0;
  let modeModifyGrantsConfig = 0;
  let modeViewGrantsConfig = 0;

  let modeCreateAccumulatorConfig = 0;
  let modeModifyAccumulatorConfig = 0;
  let modeViewAccumulatorConfig = 0;

  let modeCreateTimeUnitConfig = 0;
  let modeModifyTimeUnitConfig = 0;
  let modeViewTimeUnitConfig = 0;

  modeGetTaxConfig = checkPermissionGetTaxConfig({ listPermission });
  modeCreateTaxConfig = checkPermissionCreateTaxConfig({ listPermission });
  modeModifyTaxConfig = checkPermissionModifyTaxConfig({ listPermission });

  modeGetUomConfig = checkPermissionGetUomConfig({ listPermission });
  modeCreateUomConfig = checkPermissionCreateUomConfig({ listPermission });
  modeModifyUomConfig = checkPermissionModifyUomConfig({ listPermission });

  modeGetConfigUsageType = checkPermissionGetConfigUsageType({ listPermission });
  modeCreateConfigUsageType = checkPermissionCreateConfigUsageType({ listPermission });
  modeModifyConfigUsageType = checkPermissionModifyConfigUsageType({ listPermission });

  modeSearchTopupOffer = checkPermissionSearchTopupOffer({ listPermission });
  modeCreateTopupOffer = checkPermissionCreateTopupOffer({ listPermission });
  modeModifyTopupOffer = checkPermissionModifyTopupOffer({ listPermission });

  modeViewZoneUnit = checkPermissionViewZoneUnit({ listPermission });
  modeCreateZoneUnit = checkPermissionCreateZoneUnit({ listPermission });
  modeModifyZoneUnit = checkPermissionModifyZoneUnit({ listPermission });
  modeSearchRatedUsage = checkPermissionSearchRatedUsage({ listPermission });

  modeCreateConfigCountryCodes = checkPermissionCreateConfigCountryCodes({ listPermission });
  modeModifyConfigCountryCodes = checkPermissionModifyConfigCountryCodes({ listPermission });
  modeSearchConfigCountryCodes = checkPermissionSearchConfigCountryCodes({ listPermission });

  modeCreateRateUnitConfig = checkPermissionCreateRateUnitConfig({ listPermission });
  modeModifyRateUnitConfig = checkPermissionModifyRateUnitConfig({ listPermission });
  modeViewRateUnitConfig = checkPermissionViewRateUnitConfig({ listPermission });

  modeCreateRateUnitMapConfig = checkPermissionCreateRateUnitMapConfig({ listPermission });
  modeModifyRateUnitMapConfig = checkPermissionModifyRateUnitMapConfig({ listPermission });
  modeGetRateUnitMapConfig = checkPermissionGetRateUnitMapConfig({ listPermission });

  modeCreateRegulatoryProductCode = checkPermissionCreateRegulatoryProductCode({ listPermission });
  modeModifyRegulatoryProductCode = checkPermissionModifyRegulatoryProductCode({ listPermission });
  modeViewRegulatoryProductCode = checkPermissionViewRegulatoryProductCode({ listPermission });

  modeCreateCurrencyConfig = checkPermissionCreateCurrencyConfig({ listPermission });
  modeModifyCurrencyConfig = checkPermissionModifyCurrencyConfig({ listPermission });
  modeViewCurrencyConfig = checkPermissionViewCurrencyConfig({ listPermission });

  modeCreateGrantsConfig = checkPermissionCreateGrantsConfig({ listPermission });
  modeModifyGrantsConfig = checkPermissionModifyGrantsConfig({ listPermission });
  modeViewGrantsConfig = checkPermissionViewGrantsConfig({ listPermission });

  modeCreateAccumulatorConfig = checkPermissionCreateAccumulatorConfig({ listPermission });
  modeModifyAccumulatorConfig = checkPermissionModifyAccumulatorConfig({ listPermission });
  modeViewAccumulatorConfig = checkPermissionViewAccumulatorConfig({ listPermission });

  modeCreateTimeUnitConfig = checkPermissionCreateTimeUnitConfig({ listPermission });
  modeModifyTimeUnitConfig = checkPermissionModifyTimeUnitConfig({ listPermission });
  modeViewTimeUnitConfig = checkPermissionViewTimeUnitConfig({ listPermission });

  return {
    modeGetTaxConfig,
    modeCreateTaxConfig,
    modeModifyTaxConfig,
    modeGetUomConfig,
    modeCreateUomConfig,
    modeModifyUomConfig,
    modeGetConfigUsageType,
    modeCreateConfigUsageType,
    modeModifyConfigUsageType,
    modeSearchTopupOffer,
    modeCreateTopupOffer,
    modeModifyTopupOffer,
    modeViewZoneUnit,
    modeCreateZoneUnit,
    modeModifyZoneUnit,
    modeSearchRatedUsage,
    modeCreateConfigCountryCodes,
    modeModifyConfigCountryCodes,
    modeSearchConfigCountryCodes,
    modeCreateRateUnitConfig,
    modeModifyRateUnitConfig,
    modeViewRateUnitConfig,
    modeCreateRateUnitMapConfig,
    modeModifyRateUnitMapConfig,
    modeGetRateUnitMapConfig,
    modeCreateRegulatoryProductCode,
    modeModifyRegulatoryProductCode,
    modeViewRegulatoryProductCode,
    modeCreateCurrencyConfig,
    modeModifyCurrencyConfig,
    modeViewCurrencyConfig,
    modeCreateGrantsConfig,
    modeModifyGrantsConfig,
    modeViewGrantsConfig,
    modeCreateAccumulatorConfig,
    modeModifyAccumulatorConfig,
    modeViewAccumulatorConfig,
    modeCreateTimeUnitConfig,
    modeModifyTimeUnitConfig,
    modeViewTimeUnitConfig,
  };
};

export {
  checkPermissionGetTaxConfig,
  checkPermissionCreateTaxConfig,
  checkPermissionModifyTaxConfig,
  checkPermissionForAllPages,
  checkPermissionCreateUomConfig,
  checkPermissionModifyUomConfig,
  checkPermissionGetUomConfig,
  checkPermissionGetConfigUsageType,
  checkPermissionCreateConfigUsageType,
  checkPermissionModifyConfigUsageType,
  checkPermissionSearchTopupOffer,
  checkPermissionCreateTopupOffer,
  checkPermissionModifyTopupOffer,
  checkPermissionViewZoneUnit,
  checkPermissionCreateZoneUnit,
  checkPermissionModifyZoneUnit,
  checkPermissionSearchRatedUsage,
  checkPermissionCreateConfigCountryCodes,
  checkPermissionModifyConfigCountryCodes,
  checkPermissionSearchConfigCountryCodes,
  checkPermissionCreateRateUnitConfig,
  checkPermissionModifyRateUnitConfig,
  checkPermissionViewRateUnitConfig,
  checkPermissionCreateRateUnitMapConfig,
  checkPermissionModifyRateUnitMapConfig,
  checkPermissionGetRateUnitMapConfig,
  checkPermissionCreateRegulatoryProductCode,
  checkPermissionModifyRegulatoryProductCode,
  checkPermissionViewRegulatoryProductCode,
  checkPermissionCreateCurrencyConfig,
  checkPermissionModifyCurrencyConfig,
  checkPermissionViewCurrencyConfig,
  checkPermissionCreateGrantsConfig,
  checkPermissionModifyGrantsConfig,
  checkPermissionViewGrantsConfig,
  checkPermissionCreateAccumulatorConfig,
  checkPermissionModifyAccumulatorConfig,
  checkPermissionViewAccumulatorConfig,
  checkPermissionCreateTimeUnitConfig,
  checkPermissionModifyTimeUnitConfig,
  checkPermissionViewTimeUnitConfig,
};
