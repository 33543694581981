import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, difference, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { searchConfigCountryCodes, modifyConfigCountryCode, createConfigCountryCode } from '../../actions';
import { makeGetCountryCodes } from '../../selectors';
import { getPageTotalCount } from '../../../../utils/utils';
import { FormWithTableItem, DataTable, GenericInput, TablePagination } from '../../../../components/common';
import { CountryCodesSearchForm } from '../../../../components/BaseConfigurationsHub';
import { makeGetPermissionsPricingManagement } from '../../../App/selectors';
import {
  checkPermissionSearchConfigCountryCodes,
  checkPermissionModifyConfigCountryCodes,
  checkPermissionCreateConfigCountryCodes,
} from '../../CheckPermission';

const sortTypes = {
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  alpha2: {
    asc: 'alpha2_ASC',
    desc: 'alpha2_DESC',
  },
  alpha3: {
    asc: 'alpha3_ASC',
    desc: 'alpha3_DESC',
  },
};

class CountryCodes extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    countryCodes: [],
    isOpenModalRemoveItem: false,
    isModify: false,
    isSearching: true,
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    wasValidated: false,
    formValid: true,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldData === props.countryCodes) return null;
    return {
      countryCodes: props.countryCodes && props.countryCodes.length ? props.countryCodes : null,
      totalCount: getPageTotalCount({ ...state, items: props.countryCodes }),
      oldData: props.countryCodes,
    };
  }

  componentDidMount() {
    this.doSearchConfigCountryCodes();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { countryCodes } = this.state;
    const newData = cloneDeep(countryCodes);
    const indexItemChange = newData.findIndex(item => item.index === index);
    newData[indexItemChange][name] = value;
    this.setState({ countryCodes: newData });
  };

  onChangeCountryCode = ({ name, value, index }) => {
    const { countryCodes } = this.state;
    const newData = cloneDeep(countryCodes);
    newData[index][name] = value;
    this.setState({ countryCodes: newData });
  };

  doSearchConfigCountryCodes = () => {
    const { searchConfigCountryCodes } = this.props;
    const { filter, page, size, sorted } = this.state;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortTypes[sorted.sortCol][sorted.sortDir] : null,
    };
    searchConfigCountryCodes(payload, ({ data }) => {
      this.setState({ isSearching: false, isModify: !!(data && data.length) });
    });
  };

  onAddNewCountryCode = () => {
    const { countryCodes } = this.state;
    let newData = cloneDeep(countryCodes);
    if (!newData) newData = [];
    const newDataItem = {
      name: null,
      alpha2: null,
      alpha3: null,
      numcode: null,
      isdcode: null,
      continent: null,
      region1: null,
      region2: null,
    };
    newData = [newDataItem, ...newData];
    this.setState({ countryCodes: newData });
  };

  onHanldeSubmit = evt => {
    evt.preventDefault();
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const { isModify, countryCodes, defaultDataFromAPI } = this.state;
    const { modifyConfigCountryCode, createConfigCountryCode } = this.props;
    if (isModify) {
      const newPayloadModify = {
        countryCodesConfig: countryCodes,
      };
      const listIndexRemove = difference(
        defaultDataFromAPI.map(item => item.index),
        countryCodes.map(val => val.index)
      );
      if (listIndexRemove.length) {
        listIndexRemove.forEach(value => {
          newPayloadModify.countryCodesConfig.push({ index: value });
        });
      }
      modifyConfigCountryCode(newPayloadModify);
    } else {
      const newPayloadCreate = {
        countryCodesConfig: countryCodes,
      };
      createConfigCountryCode(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doSearchConfigCountryCodes();
        }
      });
    }
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchConfigCountryCodes());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchConfigCountryCodes());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchConfigCountryCodes());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.doSearchConfigCountryCodes());
  };

  render() {
    const { countryCodes, isSearching, wasValidated, isModify, page, size, totalCount, sorted } = this.state;
    const { t, permissionsPricing } = this.props;
    let modeSearchConfigCountryCodes = 0;
    let modeModifyConfigCountryCodes = 0;
    let modeCreateConfigCountryCodes = 0;


    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeSearchConfigCountryCodes = checkPermissionSearchConfigCountryCodes({ listPermission });
      modeModifyConfigCountryCodes = checkPermissionModifyConfigCountryCodes({ listPermission });
      modeCreateConfigCountryCodes = checkPermissionCreateConfigCountryCodes({ listPermission });
    }

    if (!modeSearchConfigCountryCodes) return null;

    const creditProfileColumns = [
      {
        name: 'name',
        label: 'label.name',
        required: true,
        sortable: true,
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.name || null}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'alpha2',
        label: 'label.alpha2',
        sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '160px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.alpha2}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="alpha2"
            required
          />
        ),
      },
      {
        name: 'alpha3',
        label: 'label.alpha3',
        sortable: true,
        required: true,
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.alpha3}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="alpha3"
            required
          />
        ),
      },
      {
        name: 'numcode',
        label: 'label.currencyCode',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.numcode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="numcode"
          />
        ),
      },
      {
        name: 'isdcode',
        label: 'label.isdcode',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.isdcode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="isdcode"
            required
          />
        ),
      },
      {
        name: 'continent',
        label: 'label.continent',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.continent}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="continent"
            required
          />
        ),
      },
      {
        name: 'region1',
        label: 'label.region1',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.region1}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="region1"
            required
          />
        ),
      },
      {
        name: 'region2',
        label: 'label.region2',
        required: true,
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, idx) => (
          <GenericInput
            value={item.region2}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeCountryCode({ name, value, index: idx })}
            name="region2"
            required
          />
        ),
      },
    ];
    return (
      <div className="col-md-12 mb-30">
        <PageTitle
          linkTo={RouteNames.currency.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:pricingHub.subMain.baseConfigurations'), url: RouteNames.currency.path },
            { name: t('baseConfigurationsPage:sidebar.countryCodes') },
          ]}
        />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div>
                  <CountryCodesSearchForm onSubmit={this.onHandleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form
          noValidate
          ref={this.formRef}
          onSubmit={modeModifyConfigCountryCodes === 2 ? this.onHanldeSubmit : () => {}}
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        >
          <FormWithTableItem title={t('baseConfigurationsPage:sidebar.countryCodes')} subClass="border-bottom">
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateConfigCountryCodes === 2 && (
                <button
                  type="button"
                  className="button button-border black x-small"
                  // disabled={countryCodes && countryCodes.length}
                  onClick={this.onAddNewCountryCode}
                >
                  +
                  {t('label.addNewCountryCodeMapping')}
                </button>
              )}
              {modeModifyConfigCountryCodes === 2 && (
                <button
                  // disabled={countryCodes && countryCodes.length}
                  type="submit"
                  className="button button-border x-small float-right"
                >
                  {isModify ? t('label.saveConfig') : t('label.saveConfig')}
                </button>
              )}
              <button
                type="button"
                onClick={() => this.doSearchConfigCountryCodes()}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
            <div>
              <DataTable
                columns={creditProfileColumns}
                data={countryCodes && countryCodes.length ? countryCodes : []}
                isLoading={isSearching}
                sorted={sorted}
                onSort={this.onSortColumn}
                isFixedHeaderTable
              />
            </div>
          </FormWithTableItem>
        </form>
        <br />
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

CountryCodes.propTypes = {
  searchConfigCountryCodes: PropTypes.func.isRequired,
  modifyConfigCountryCode: PropTypes.func.isRequired,
  createConfigCountryCode: PropTypes.func.isRequired,
  countryCodes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

CountryCodes.defaultProps = {
  countryCodes: [],
};

const mapStateToProps = createStructuredSelector({
  countryCodes: makeGetCountryCodes() || [],
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { searchConfigCountryCodes, modifyConfigCountryCode, createConfigCountryCode })(CountryCodes)
);
